import React from 'react';
import { EmptyState } from '../../utilities/components/emptyState';
import illustration from '../../asset/Illustration.svg';
import announcement from '../../asset/announcement.svg';
import arrow_right from '../../asset/chevron-right.svg';
import { ReactSVG } from 'react-svg';

function Anouncement() {
  return (
    <div className='p-6 border rounded w-full'>
      <div>
        <div className='flex justify-between items-center pb-4 border-b border-[#EAECF0] '>
          <div className='flex items-center gap-4'>
            <ReactSVG src={announcement} />
            <p className='text-lg font-medium text-greenDark'>What&apos;s happening at QuiickOps</p>
          </div>
          <div className='flex items-center gap-4'>
            <p className='text-lg font-medium text-greenDark'>Announcements</p>
            <ReactSVG src={arrow_right} />
          </div>
        </div>
      </div>
      <div className='flex px-44'>
        <EmptyState
          title=''
          subtitle=''
          message='You don’t have any notifications at the moment...'
          link=''
          actionLabel=''
          icon={illustration}
          status='default'
        />
      </div>
    </div>
  );
}

export default Anouncement;
