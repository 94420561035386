import React from "react";
import UserCardsHeader from "../../../utilities/headers/userCardsHeader";
import IndividualCelebrations from "./individualCelebration";
import star from "../../../asset/star.svg";
import arrow from "../../../asset/chevron-right.svg";
import { Birthdays } from "../../../utilities/types/types";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

interface BirthdayProps {
  userData: Birthdays[];
}

function Birthday({ userData }: BirthdayProps) {
  const first3Users = userData.slice(0, 3);
  return (
    <div className="w-[50%] p-6 my-3 border border-solid rounded-lg">
      <UserCardsHeader icon={star} title="Celebrations" />

      {userData && (
        <div className="mt-4 overflow-y-scroll h-64">
          <div className="space-y-4">
            {first3Users.map((user, index) => (
              <IndividualCelebrations key={index} userData={user} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Birthday;
