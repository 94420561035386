import React, { useEffect, useMemo, useState } from 'react';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';

import { FileValue } from '../../../../component/utilities/types/types';
import { EmptyState } from '../../../utilities/components/emptyState';
import Table from '../../../../component/utilities/table/table';
import Pagination from '../../../utilities/table/pagination';
import GlobalFilter from '../../../utilities/table/globalFilter';
import { deleteDocument } from '../../../redux/slices/delDoc';
import { useAppDispatch } from '../../../redux/hook/authHook';
import { toast } from 'react-toastify';

type documentTableProps = {
  docs: FileValue[];
  rowCount: number;
};

function DocumentTable({ rowCount, docs }: documentTableProps) {
  const [data, setData] = useState(
    () =>
      docs?.map((doc) => ({
        _id: doc._id,
        fileName: doc.fileName,
        fileSize: doc.fileSize,
        dateUploaded: doc.dateUploaded,
        uploadedBy: doc.uploadedBy,
        documentType: doc.documentType,
        fileUrl: doc.fileUrl,
      })) || []
  );

  useEffect(() => {
    setData(
      docs?.map((doc) => ({
        _id: doc._id,
        fileName: doc.fileName,
        fileSize: doc.fileSize,
        dateUploaded: doc.dateUploaded,
        uploadedBy: doc.uploadedBy,
        documentType: doc.documentType,
        fileUrl: doc.fileUrl,
      })) || []
    );
  }, [docs]);

  const dispatch = useAppDispatch();

  const handleDelete = async (id: string) => {
    try {
      const response = await dispatch(deleteDocument(id));
      if (response.payload.statusCode === 200) {
        toast.success('Document deleted successfully', {
          position: 'top-center',
          autoClose: 3000,
          style: { backgroundColor: '#00989B', color: 'white' },
        });
        window.location.reload();
      } else {
        toast.error('Failed to delete document.');
      }
    } catch (error) {
      console.error('Error occurred while deleting:', error);
      toast.error('An error occurred while deleting the document.');
    }
  };

  const columns: Column<FileValue>[] = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({
          getToggleAllRowsSelectedProps,
        }: {
          getToggleAllRowsSelectedProps: any;
        }) => (
          <div>
            <input type='checkbox' {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: { row: any }) => (
          <div>
            <input type='checkbox' {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      {
        Header: 'File Name',
        accessor: 'fileName',
        Cell: ({
          row,
        }: {
          row: { original: { fileUrl: string; fileName: string } };
        }) => {
          const { fileUrl, fileName } = row.original;

          if (fileUrl) {
            return (
              <a
                href={fileUrl}
                download={fileName}
                className='hover:text-greenDark'
              >
                {fileName}
              </a>
            );
          }
          return <span>No File URL</span>;
        },
      },
      {
        Header: 'File Size',
        accessor: 'fileSize',
      },
      {
        Header: 'Document Type',
        accessor: 'documentType',
      },
      {
        Header: 'Date Uploaded',
        accessor: 'dateUploaded',
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({ row }: { row: { original: { _id: string } } }) => (
          <button onClick={() => handleDelete(row.original._id)}>
            <svg
              width='20'
              height='21'
              viewBox='0 0 20 21'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M2.5 5.50008H4.16667M4.16667 5.50008H17.5M4.16667 5.50008V17.1667C4.16667 17.6088 4.34226 18.0327 4.65482 18.3453C4.96738 18.6578 5.39131 18.8334 5.83333 18.8334H14.1667C14.6087 18.8334 15.0326 18.6578 15.3452 18.3453C15.6577 18.0327 15.8333 17.6088 15.8333 17.1667V5.50008H4.16667ZM6.66667 5.50008V3.83341C6.66667 3.39139 6.84226 2.96746 7.15482 2.6549C7.46738 2.34234 7.89131 2.16675 8.33333 2.16675H11.6667C12.1087 2.16675 12.5326 2.34234 12.8452 2.6549C13.1577 2.96746 13.3333 3.39139 13.3333 3.83341V5.50008M8.33333 9.66675V14.6667M11.6667 9.66675V14.6667'
                stroke='#B42318'
                stroke-width='1.67'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </button>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setGlobalFilter,
    setPageSize,
  } = useTable<FileValue>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className='mx-auto my-16'>
      {data.length === 0 ? (
        <EmptyState
          title='No Document found'
          subtitle=''
          message='Kindly upload your document.'
          link=''
          actionLabel=''
          icon=''
          status='error'
        />
      ) : (
        <div>
          <div>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={data}
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default DocumentTable;
