import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Button } from "../button";
import Modal from "../components/modal";

interface TitleSectionProps {
  title?: string;
  subtitle?: string;
  buttonSubtitle?: string;
  greenButtonText?: string;
  greenButtonIcon?: string;
  lightButtonText?: string;
  lightButtonIcon?: string;
  grayButtonIcon?: string;
  grayButtonText?: string;
  onRejectButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  onSaveButtonClick?: () => void;
  lightBtnLink?: string;
  greenBtnLink?: string;
  grayBtnLink?: string;
  expenseType?: string;
  showModal?: boolean;
  closeModal?: () => void;
  modalContentStyle?: string;
  children?: ReactNode;
  size?: "lg" | "xs" | "sm" | "md" | "xl" | "full";
}
function TitleSection({
  title,
  subtitle,
  greenButtonText,
  onCancelButtonClick,
  onSaveButtonClick,
  onRejectButtonClick,
  greenBtnLink,
  grayButtonText,
  grayBtnLink,
  lightButtonText,
  lightBtnLink,
  lightButtonIcon,
  greenButtonIcon,
  grayButtonIcon,
  expenseType,
  buttonSubtitle,
  showModal,
  closeModal,
  modalContentStyle,
  children,
  size = "lg",
}: TitleSectionProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   setIsModalOpen(!!showModal);
  // }, [showModal]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (closeModal) {
      closeModal(); // Invoke the provided closeModal function if available
    }
  };
  return (
    <div>
      <div className="flex justify-between pt-4 pb-8">
        <div>
          <h3 className="pb-1 text-2xl text-grayText">{title}</h3>
          <div className="flex gap-4">
            {subtitle && (
              <p className="text-lg font-light text-[#667085]">{subtitle}</p>
            )}
            {expenseType && (
              <p
                style={{
                  backgroundColor:
                    expenseType === "Review"
                      ? "green"
                      : expenseType === "Approved"
                      ? "#ECFDF3"
                      : expenseType === "Request"
                      ? "#FFF6ED"
                      : "#EFF8FF",
                  borderColor: "transparent",
                  textAlign: "center",
                  padding: "2px 8px",
                  borderRadius: "16px",
                  color:
                    expenseType === "Request"
                      ? "#C4320A"
                      : expenseType === "Review"
                      ? "green"
                      : expenseType === "Approved"
                      ? "#027A48"
                      : "#175CD3",
                }}
              >
                {expenseType}
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center">
          {greenButtonText && (
            <>
              <div className="">
                <Button
                  className="border border-transparent bg-greenDark text-white mr-4 rounded-lg px-[18px] py-[10px]"
                  onClick={() => {
                    if (onSaveButtonClick) {
                      onSaveButtonClick(); // Execute onSaveButtonClick if provided
                    }
                    if (showModal) {
                      setIsModalOpen(true); // Open modal if showModal is true
                    }
                  }}
                >
                  <div className="flex items-center">
                    {greenButtonIcon && (
                      <ReactSVG src={greenButtonIcon} className="p-1" />
                    )}
                    <Link
                      to={greenBtnLink || "#"}
                      className="pl-1 no-underline"
                    >
                      {greenButtonText}
                    </Link>
                  </div>
                </Button>
                {buttonSubtitle && (
                  <p className="text-xs font-light">{buttonSubtitle}</p>
                )}
              </div>
            </>
          )}
          {lightButtonText && (
            <Button
              className="border border-transparent text-greenDark bg-greenLight   mr-4 rounded-lg px-[18px] py-[10px]"
              onClick={onRejectButtonClick}
            >
              {lightButtonIcon && (
                <div className="flex items-center">
                  <ReactSVG src={lightButtonIcon} className="p-1" />
                  <Link to={lightBtnLink || "#"} className="pl-1 no-underline">
                    {lightButtonText}
                  </Link>
                </div>
              )}
              {!lightButtonIcon && (
                <Link to={lightBtnLink || "#"} className="no-underline">
                  {lightButtonText}
                </Link>
              )}
            </Button>
          )}
          {grayButtonText && (
            <Button
              className="border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px]"
              onClick={onCancelButtonClick}
            >
              {grayButtonIcon && (
                <div className="flex items-center">
                  <ReactSVG src={grayButtonIcon} className="p-1" />
                  <Link to={grayBtnLink || "#"} className="pl-1 no-underline">
                    {grayButtonText}
                  </Link>
                </div>
              )}
              {!grayButtonIcon && (
                <Link to={grayBtnLink || "#"} className="no-underline">
                  {grayButtonText}
                </Link>
              )}
            </Button>
          )}
          <Modal isOpen={isModalOpen} onClose={handleModalClose} size={size}>
            <div className={modalContentStyle}>{children}</div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default TitleSection;
