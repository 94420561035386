import Hr from "../../../../asset/hr.svg";
import Ex from "../../../../asset/exmgt.svg";
import Benefit from "../../../../asset/benefit.svg";
import dash from "../../../../asset/dashboard.svg";
import Help from "../../../../asset/help.svg";
import Settings from "../../../../asset/set.svg";
import { ReactSVG } from "react-svg";

export const data = {
  admin: [
    // {
    //   name: {
    //     title: 'HR Database',
    //     className: 'pl-5',
    //     icon: <ReactSVG src={Hr} />,
    //   },
    //   items: [
    //     // {
    //     //   name: 'Onboarding',
    //     //   link: '/admin/onboarding',
    //     // },
    //     // // {
    //     // //   name: "Employees",
    //     // //   link: "/admin/employees",
    //     // // },
    //     // {
    //     //   name: 'Exit Management',
    //     //   link: '/admin/exit',
    //     // },
    //   ],
    // },
    {
      name: {
        title: "Dashboard",
        className: "pl-5",
        icon: <ReactSVG src={dash} />,
        link: "/user/dashboard",
      },
    },
    {
      name: {
        title: "Compensation and Benefit",
        className: "pl-5",
        icon: <ReactSVG src={Benefit} />,
      },
      items: [
        {
          name: "Payslip",
          link: "/user/payslip",
        },
      ],
    },
    {
      name: {
        title: "Expense Management",
        className: "pl-5",
        icon: <ReactSVG src={Ex} />,
      },
      items: [
        {
          name: "Expense Summary",
          link: "/user/expense",
        },
      ],
    },
    {
      name: {
        title: "Leave Management",
        className: "pl-5",
        icon: <ReactSVG src={Hr} />,
      },
      items: [
        {
          name: "Leave Summary",
          link: "/user/leave-summary",
        },
      ],
    },
    // {
    //   name: {
    //     title: 'Time Management',
    //     className: 'pl-18',
    //     icon: <ReactSVG src={Tm} />,
    //   },
    //   items: [
    //     {
    //       name: 'Time Management',
    //       link: '/user/unsubscribe',
    //     },
    //   ],
    // },
    // {
    //   name: {
    //     title: 'Marketing',
    //     className: 'pl-20',
    //     icon: <ReactSVG src={Marketing} />,
    //   },
    //   items: [
    //     {
    //       name: 'Marketing',
    //       link: '/user/unsubscribe',
    //     },
    //   ],
    // },
    // {
    //   name: {
    //     title: 'Project Management',
    //     className: 'pl-10',
    //     icon: <ReactSVG src={Pm} />,
    //   },
    //   items: [
    //     {
    //       name: 'Project Management',
    //       link: '/user/unsubscribe',
    //     },
    //   ],
    // },
  ],
};
export const datafooter = {
  admin: [
    {
      name: "",
      items: [
        {
          title: "Settings",
          icon: <ReactSVG src={Settings} />,
          className: " cursor-pointer border-b border-white",
          link: "/user/settings",
        },
        {
          title: "Help and Getting started",
          icon: <ReactSVG src={Help} />,
          className: "",
          link: "",
        },
      ],
    },
  ],
};
