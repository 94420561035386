import React, { useEffect, useMemo, useState } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import filter from "../../asset/Filters.svg";
import { useParams } from "react-router-dom";
import { Payslip } from "../../utilities/types/types";
import RowRange from "../../utilities/table/rowsCount";
import GlobalFilter from "../../utilities/table/globalFilter";
import { ButtonIconLink } from "../../utilities/button";
import { EmptyState } from "../../utilities/components/emptyState";
import Table from "../../utilities/table/table";
import Pagination from "../../utilities/table/pagination";

type payslipTableProps = {
  paySlip: Payslip[];
  rowCount: number;
};

function PayslipTable({ rowCount, paySlip }: payslipTableProps) {
  const [data, setData] = useState(
    () =>
      paySlip?.map((payslip) => ({
        grossPay: payslip.grossPay,
        date: payslip.date,
        contractType: payslip.contractType,
        companyBenefits: payslip.companyBenefits,
        companyTaxes: payslip.companyTaxes,
        payslip: payslip.payslip,
      })) || []
  );
  useEffect(() => {
    setData(
      paySlip?.map((payslip) => ({
        grossPay: payslip.grossPay,
        date: payslip.date,
        contractType: payslip.contractType,
        companyBenefits: payslip.companyBenefits,
        companyTaxes: payslip.companyTaxes,
        payslip: payslip.payslip,
      })) || []
    );
  }, [paySlip]);
  const columns: Column<Payslip>[] = useMemo(
    () => [
      {
        id: "selection",
        Header: ({
          getToggleAllRowsSelectedProps,
        }: {
          getToggleAllRowsSelectedProps: any;
        }) => (
          <div>
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: { row: any }) => (
          <div>
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      {
        Header: "Gross Pay ",
        accessor: "grossPay",
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value }: { value: string }) => (
          <span className="text-greenDark">{value}</span>
        ),
      },
      {
        Header: "Contract Type",
        accessor: "contractType",
      },
      {
        Header: "Company Benefits",
        accessor: "companyBenefits",
      },
      {
        Header: "Company Taxes",
        accessor: "companyTaxes",
      },
      {
        Header: "Payslip",
        accessor: "payslip",
        Cell: ({ value }: { value: string }) => {
          if (value) {
            return (
              <a
                href={value}
                target="_blank"
                rel="noopener noreferrer"
                className="flex hover:text-greenDark"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.75 4C4.75 2.20508 6.20508 0.75 8 0.75H24C24.1212 0.75 24.2375 0.798159 24.3232 0.883885L35.1161 11.6768C35.2018 11.7625 35.25 11.8788 35.25 12V36C35.25 37.7949 33.7949 39.25 32 39.25H8C6.20507 39.25 4.75 37.7949 4.75 36V4Z"
                    stroke="#D0D5DD"
                    stroke-width="1.5"
                  />
                  <path
                    d="M24 0.5V8C24 10.2091 25.7909 12 28 12H35.5"
                    stroke="#D0D5DD"
                    stroke-width="1.5"
                  />
                  <path
                    d="M25.7088 25.3438C24.586 25.3438 23.1808 25.5396 22.7211 25.6085C20.8184 23.6216 20.277 22.4922 20.1553 22.191C20.3203 21.7671 20.8944 20.1564 20.976 18.0878C21.0162 17.0521 20.7975 16.2782 20.3256 15.7876C19.8545 15.2979 19.2843 15.2598 19.1208 15.2598C18.5475 15.2598 17.5857 15.5497 17.5857 17.4911C17.5857 19.1756 18.3711 20.9631 18.5882 21.4249C17.4443 24.7554 16.2163 27.0353 15.9556 27.5042C11.3594 29.2347 11 30.9079 11 31.3821C11 32.2342 11.6069 32.743 12.6234 32.743C15.0932 32.743 17.347 28.5965 17.7197 27.8765C19.4738 27.1776 21.8216 26.7446 22.4184 26.6413C24.1304 28.2721 26.1104 28.7073 26.9326 28.7073C27.5512 28.7073 28.9999 28.7073 28.9999 27.2177C29 25.8345 27.2271 25.3438 25.7088 25.3438ZM25.5898 26.3216C26.9238 26.3216 27.2764 26.7628 27.2764 26.9961C27.2764 27.1424 27.2208 27.62 26.5058 27.62C25.8646 27.62 24.7575 27.2494 23.6683 26.4454C24.1225 26.3857 24.7947 26.3216 25.5898 26.3216ZM19.0508 16.2076C19.1724 16.2076 19.2525 16.2467 19.3185 16.3383C19.7022 16.8706 19.3928 18.6103 19.0163 19.9716C18.6528 18.8043 18.38 17.0133 18.7638 16.3831C18.8388 16.2602 18.9246 16.2076 19.0508 16.2076ZM18.4029 26.6275C18.8859 25.6517 19.4272 24.2297 19.722 23.4255C20.3121 24.4131 21.1057 25.3301 21.5647 25.8272C20.1356 26.1285 19.0543 26.4295 18.4029 26.6275ZM11.9594 31.5123C11.9276 31.4745 11.9229 31.395 11.9469 31.2994C11.9971 31.0993 12.3814 30.1072 15.1608 28.8639C14.7628 29.4907 14.1407 30.3864 13.4572 31.0556C12.9761 31.5059 12.6014 31.7343 12.3437 31.7343C12.2515 31.7343 12.1244 31.7092 11.9594 31.5123Z"
                    fill="#D92D20"
                  />
                </svg>
                <div> Download Payslip</div>
              </a>
            );
          }
          return <span>No Payslip</span>;
        },
      },
      // {
      //   Header: 'Payslip',
      //   accessor: 'payslip',
      // },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<Payslip>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="mx-auto border-2 border-solid rounded-lg border-[#EAECF0]">
      <div className="">
        <div className="flex justify-start gap-2 px-6 py-5">
          <p className="text-lg text-[#101828] ">All Payslip</p>
          <div className="border rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]">
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center lg:gap-[50%] xl:gap-[50%] md:[25%] my-3 mx-4 ">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <ButtonIconLink
          className="pt-[10px] pb-[7px] px-4"
          src={filter}
          link="#"
          text="Filter"
        />
      </div>

      {data.length === 0 ? (
        <EmptyState
          title="No payslip found"
          subtitle=""
          message="Kindly wait for your payslip to be ready."
          link=""
          actionLabel=""
          icon=""
          status="error"
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={data}
            editBasePath="/admin/edit-expense"
            viewBasePath="/admin/view-expense"
            tooltip
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default PayslipTable;
