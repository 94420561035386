import React, { useMemo } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import filter from "../../asset/Filters.svg";
import RowRange from "../../utilities/table/rowsCount";
import GlobalFilter from "../../utilities/table/globalFilter";
import { ButtonIconLink } from "../../utilities/button";
import { EmptyState } from "../../utilities/components/emptyState";
import Table from "../../utilities/table/table";
import Pagination from "../../utilities/table/pagination";
import { Leave, LeaveTableProps } from "../../utilities/types/types";

function AllLeave({ leave, rowCount }: LeaveTableProps) {
  const data = useMemo(() => {
    return leave.map((leaveItem) => ({
      employee: leaveItem.employeeName,
      startDate: leaveItem.startDate,
      endDate: leaveItem.endDate,
      backStop: leaveItem.backStop,
      notes: leaveItem.notes,
      status: leaveItem.status,
      leaveType: leaveItem.leaveType,
      _id: leaveItem._id,
      duration: leaveItem.duration,
      employeeName: leaveItem.employeeName,
      profileImage: leaveItem.profileImage,
    }));
  }, [leave]);

  const columns: Column<Leave>[] = useMemo(
    () => [
      {
        Header: "Leave Type",
        accessor: "leaveType",
      },
      {
        Header: "Duration",
        accessor: "duration",
      },
      {
        Header: "Start Date",
        accessor: (row) => new Date(row.startDate).toLocaleDateString(),
      },
      {
        Header: "End Date",
        accessor: (row) => new Date(row.endDate).toLocaleDateString(),
      },
      {
        Header: "Relief Staff",
        accessor: "backStop",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => {
          const capitalizeFirstLetter = (str: string) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
          };

          const capitalizedValue = capitalizeFirstLetter(value);

          return (
            <div>
              <p
                style={{
                  backgroundColor:
                    capitalizedValue.toLowerCase() === "review"
                      ? "green"
                      : capitalizedValue.toLowerCase() === "approved"
                      ? "#ECFDF3"
                      : "#FFF6ED",
                  borderColor: "transparent",
                  textAlign: "center",
                  padding: "2px 8px",
                  borderRadius: "16px",
                  color:
                    capitalizedValue.toLowerCase() === "request"
                      ? "#C4320A"
                      : capitalizedValue.toLowerCase() === "review"
                      ? "green"
                      : capitalizedValue.toLowerCase() === "approved"
                      ? "#027A48"
                      : "#C4320A",
                }}
              >
                {capitalizedValue}
              </p>
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<Leave>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 }, // Set initial page index and page size
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="mx-auto border-2 border-solid rounded-lg border-[#EAECF0]">
      <div>
        <div className="flex justify-start gap-2 px-6 py-5">
          <p className="text-lg text-grayText ">All Leave Requests</p>
          <div className="border rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]">
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center lg:gap-[50%] xl:gap-[50%] md:gap-[25%] my-3 mx-4 ">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <ButtonIconLink
          className="pt-[10px] pb-[7px] px-4 item-center"
          src={filter}
          link="#"
          text="Filters"
        />
      </div>

      {data.length === 0 ? (
        <EmptyState
          title="No leave data found"
          subtitle=""
          message="Kindly “Create Leave Requests” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status="error"
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={data}
            viewBasePath="/user/view-leave"
            tooltip
            tooltipButtons={{
              view: true,
            }}
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default AllLeave;
