import { useMemo } from "react";
import { ProfileData } from "../../utilities/types/types";
import { useTable } from "react-table";

interface EmploymentProps {
  user: ProfileData;
}

function formatDateString(date: Date | string | undefined) {
  if (!date) return ""; // Return an empty string if 'date' is undefined or falsy

  const formattedDate = typeof date === "string" ? new Date(date) : date;
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return formattedDate.toLocaleDateString("en-US", options);
}

const EmploymentStatus = ({ user }: EmploymentProps) => {
  const formattedDateEmployed = formatDateString(user?.dateEmployed);

  const COLUMNS: any = [
    {
      Header: "Employment Date",
      accessor: "dateEmployed",
    },
    {
      Header: "Employment Status",
      accessor: "employmentType",
    },
  ];

  const data = useMemo(
    () => [
      {
        dateEmployed: formattedDateEmployed,
        employmentType: user?.employmentType,
      },
    ],
    [formattedDateEmployed, user?.employmentType]
  );

  const columns = useMemo(() => COLUMNS, []);

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div>
      <table {...getTableProps()} className="w-[100%] border-collapse">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((columns) => (
                <th
                  {...columns.getHeaderProps()}
                  className="p-2 bg-[#F2F4F7] font-normal"
                >
                  {columns.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="">
                {row.cells.map((data) => {
                  return (
                    <td {...data.getCellProps()} className="p-2 text-center">
                      {data.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmploymentStatus;
