import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import TitleSection from "../../utilities/headers/titleSection";
import CustomInput from "../../utilities/forms/customInput";
import CustomSelect from "../../utilities/components/select";
import { Button } from "../../utilities/button";
import { LeaveData } from "../../utilities/types/types";
import {
  getLeaveCategory,
  setLeaveCategory,
} from "../../redux/slices/leave/categories";
import { createNewLeave } from "../../redux/slices/leave/createLeave";
import {
  getAllEmployee,
  setAllEmployee,
} from "../../redux/slices/employes/employee";
import { differenceInBusinessDays } from "date-fns";

type OptionType = {
  value: string;
  label: string;
};

function NewLeave() {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const initialValues: LeaveData = {
    leaveType: "",
    endDate: "",
    startDate: "",
    duration: "",
    backstop: "",
    notes: "",
  };

  useEffect(() => {
    dispatch(getLeaveCategory()).then((response) => {
      dispatch(setLeaveCategory(response.payload));
    });
    dispatch(getAllEmployee()).then((response) => {
      dispatch(setAllEmployee(response.payload));
    });
  }, [dispatch]);

  const categories = useAppSelector((state) => state.leaveCategory.category);
  const employees = useAppSelector((state) => state.active.allEmployee);
  const employeesOptions = employees.map((employee) => ({
    value: employee._id,
    label: employee.firstName + " " + employee.lastName,
  }));
  const leaveTypeOptions = categories.map((category) => ({
    value: category._id,
    label: category.leaveName,
  }));

  const validationSchema = Yup.object().shape({
    leaveType: Yup.string().required("Please select an option"),

    duration: Yup.number()
      .required("Duration is required")
      .min(1, "Duration must be at least 1 day")
      .test(
        "max-duration",
        "Duration must not exceed the acceptable duration for this leave type",
        function (value) {
          const { leaveType } = this.parent;
          const selectedLeaveType = categories.find(
            (category) => category._id === leaveType
          );
          if (!selectedLeaveType) return true; // Skip validation if no leaveType is selected
          const acceptableDuration = selectedLeaveType.acceptableDuration;
          return value <= acceptableDuration;
        }
      ),

    // Start Date validation
    startDate: Yup.date()
      .required("Start date is required")
      .test(
        "is-before-end-date",
        "Start date must be before or equal to end date",
        function (value) {
          const { endDate } = this.parent;
          return endDate ? new Date(value) <= new Date(endDate) : true;
        }
      ),

    // Merged End Date validation
    endDate: Yup.date()
      .required("End date is required")
      .test(
        "is-after-start-date",
        "End date must be after or equal to start date",
        function (value) {
          const { startDate } = this.parent;
          return startDate ? new Date(value) >= new Date(startDate) : true;
        }
      )
      .test(
        "max-business-days",
        "The number of work days between Start Date and End Date must not exceed the entered Duration",
        function (value) {
          const { startDate, duration } = this.parent;
          if (!startDate || !value || !duration) return true;
          const workDays = differenceInBusinessDays(
            new Date(value),
            new Date(startDate)
          );
          return workDays <= duration;
        }
      ),

    // Backstop validation
    backstop: Yup.string().required("Please select an option"),

    // Notes field validation
    notes: Yup.string(),
  });

  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSubmit = async (data: LeaveData) => {
    setLoading(true);
    try {
      const res = await dispatch(createNewLeave(data));
      setLoading(false);
      navigate("/user/leave-summary");
      toast.success("Leave created successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: "#00989B", color: "white" },
      });
    } catch (error: any) {
      setLoading(false);

      if (error?.response?.status === 400) {
        toast.error("Bad request. Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      }
    }
  };

  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/40">
          {/* Loading indicator */}
        </div>
      )}
      <div>
        <TitleSection
          title="New Leave"
          subtitle="Fill out required information and save."
        />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <div className="flex gap-10 px-4">
                <CustomSelect
                  label="Type"
                  name="leaveType"
                  options={leaveTypeOptions}
                  wrapperClassName="pt-[2px]"
                  isMulti={false}
                  className="focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
                />
                <CustomInput
                  id="duration"
                  label="Duration"
                  type="number"
                  name="duration"
                  value={formik.values.duration}
                  onChange={formik.handleChange}
                  error={formik.errors.duration}
                  className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[300px] lg:w-[500px] "
                  wrapperClassName=""
                  placeholder=""
                />
              </div>
              <div className="flex gap-10 px-4">
                <CustomInput
                  id="startDate"
                  label="Start Date"
                  type="date"
                  name="startDate"
                  className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[500px]"
                  wrapperClassName=""
                  placeholder="Select Date"
                  value={formik.values.startDate}
                  onChange={formik.handleChange}
                  error={formik.errors.startDate}
                />
                <CustomInput
                  id="endDate"
                  label="End Date"
                  type="date"
                  name="endDate"
                  className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[500px]"
                  wrapperClassName=""
                  placeholder="Select Date"
                  value={formik.values.endDate}
                  onChange={formik.handleChange}
                  error={formik.errors.endDate}
                />
              </div>
              <div className="flex gap-10 px-4 mb-4">
                <CustomSelect
                  name="backstop"
                  label="Backstopper"
                  options={employeesOptions}
                  isMulti={false}
                  className="focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
                />
              </div>

              <div className="px-4 mt-6">
                <CustomInput
                  id="notes"
                  label="Notes"
                  type="textarea"
                  name="notes"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                  error={formik.errors.notes}
                  className="w-[98%] p-2 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 h-44"
                  wrapperClassName=""
                  placeholder=""
                />
              </div>

              <div className="flex justify-end pr-7">
                <Button
                  type="submit"
                  className="border text-white border-transparent bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left"
                >
                  Submit Request
                </Button>
                <Button
                  type="button"
                  className="border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default NewLeave;
