import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Auth_API from "../../../api/authService/authservice";
import { Passwords } from "../../../utilities/types/types";

export const passwordUpdate = createAsyncThunk(
  "password",
  async (data: Passwords, thunkAPI) => {
    try {
      const response = await Auth_API.updatePassword(data);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const passwordUpdateSlice = createSlice({
  name: "password",
  initialState: {
    formData: {
      currentPassword: "",
      confirmNewPassword: "",
      confirmPassword: "",
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        currentPassword: "",
        confirmNewPassword: "",
        confirmPassword: "",
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } =
  passwordUpdateSlice.actions;

export default passwordUpdateSlice.reducer;
export type RootState = ReturnType<typeof passwordUpdateSlice.reducer>;
