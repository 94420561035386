import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  PayslipData } from '../../utilities/types/types';
import Payslip_API from '../../api/payslipService';


interface PayslipState {
  payslip: PayslipData;
}

export const getPayslipData = createAsyncThunk('payslip', async () => {
  try {
    const response = await Payslip_API.getPayslipData();
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const payslipDataSlice = createSlice({
  name: 'payslip',
  initialState: {
    payslip: {} as PayslipData,
  } as PayslipState,
  reducers: {
    setPayslipData: (state, { payload }) => {
      state.payslip = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allExpense.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setPayslipData } = payslipDataSlice.actions;

export default payslipDataSlice.reducer;