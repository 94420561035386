import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Auth_API from '../../../api/authService/authservice';
import { EmergencyValues } from '../../../utilities/types/types';

export const emergencyContactCreation = createAsyncThunk(
  'createEmergencyContact',
  async (data: EmergencyValues, thunkAPI) => {
    try {
      const response = await Auth_API.emergencyContact(data);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
// Replace 'YOUR_SLICE_NAME' with an appropriate name for your slice
const emergencyContactCreateSlice = createSlice({
  name: 'EmergencyContact',
  initialState: {
    formData: {
      fullName: '',
      relationship: '',
      email: '',
      workPhone: '',
      mobilePhone: '',
      homePhone: '',
      homeAddress: '',
      primaryContact: false,
      city: '',
      country: '',
      state: '',
      zipCode: 0,
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        fullName: '',
        relationship: '',
        email: '',
        workPhone: '',
        mobilePhone: '',
        homePhone: '',
        homeAddress: '',
        primaryContact: false,
        city: '',
        country: '',
        state: '',
        zipCode: 0,
      };
      state.error = null;
    },
  },
});

// Export actions
export const { setFormData, setErrors, resetForm } =
  emergencyContactCreateSlice.actions;

export default emergencyContactCreateSlice.reducer;
export type RootState = ReturnType<typeof emergencyContactCreateSlice.reducer>;
