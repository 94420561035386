import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  LeaveId, LeaveValue } from '../../../utilities/types/types';
import Leave_API from '../../../api/leaveService';


interface OneLeaveState {
  oneLeave: LeaveValue;
}

export const getOneLeave = createAsyncThunk<LeaveValue, LeaveId>(
    'oneLeave/getOneLeave',
    async (leaveId: LeaveId) => {
      try {
        const response = await Leave_API.oneLeave(leaveId);
        return response.data.data;
      } catch (error) {
        throw error;
      }
    }
  );
  
  // Create a slice
  const oneLeaveSlice = createSlice({
    name: 'oneLeave',
    initialState: {
      oneLeave: {} as LeaveValue,
    } as OneLeaveState,
    reducers: {
      setOneLeave: (state, { payload }) => {
        state.oneLeave = payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getOneLeave.pending, (state) => {
          // Handle pending state if needed
        })
        .addCase(getOneLeave.fulfilled, (state, action) => {
          state.oneLeave = action.payload;
        })
        .addCase(getOneLeave.rejected, (state, action) => {
          // Handle rejected state if needed
        });
    },
  });
  
  export const { setOneLeave } = oneLeaveSlice.actions;
  
  export default oneLeaveSlice.reducer;