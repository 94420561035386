import { Routes, Route, Navigate } from "react-router-dom";

import "./App.css";

import { ToastContainer } from "react-toastify";
import Login from "./component/auth/auth/logIn";
import PrivateRoute from "./component/routes/privateRoute";
import { userRoutes } from "./component/routes/routes";
import AccessDenied from "./component/utilities/components/accessDenied";
import ErrorPage from "./component/utilities/components/notFound";

function App() {
  return (
    <>
      <Routes>
        {/* Non-authenticated routes */}
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={<Navigate to="/login" />} // Instead of to prop, use Navigate component to handle redirection
        />
        {/* <Route path='/forgotPassword' element={<ForgotPasswordPage />} /> */}

        {/* Authenticated routes */}
        <Route path="/user" element={<PrivateRoute />}>
          {userRoutes.map(route => {
            return <Route path={route.path} element={route.component} />;
          })}
        </Route>

        <Route path="/accessDenied" element={<AccessDenied />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ToastContainer hideProgressBar />
    </>
  );
}

export default App;
