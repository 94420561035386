import React, { FC, ReactNode } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  modalContentStyle?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full' | 'fit';
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  modalContentStyle,
  size = 'small', // Set default size to 'small'
}) => {
  if (!isOpen) return null;

  let modalSizeClass = '';
  let closeIconSize = 'text-lg';

  if (size === 'xs') {
    modalSizeClass = 'w-[544px] h-[180px]';
    closeIconSize = 'text-lg';
  } else if (size === 'sm') {
    modalSizeClass = 'w-[560px] h-[206px]';
    closeIconSize = 'text-xl';
  } else if (size === 'md') {
    modalSizeClass = 'w-[580px] h-[226px]';
    closeIconSize = 'text-3xl';
  } else if (size === 'lg') {
    modalSizeClass = 'w-[580px] h-[246px]';
    closeIconSize = 'text-lg';
  } else if (size === 'xl') {
    modalSizeClass = 'w-1/3 h-[550px]';
    closeIconSize = 'text-xl';
  } else if (size === 'full') {
    modalSizeClass = 'w-full h-full';
    closeIconSize = 'text-3xl';
  } else if (size === 'fit') {
    modalSizeClass = 'w-fit h-fit';
    closeIconSize = 'text-xl';
  }

  return (
    <div className='fixed inset-0 flex items-center justify-center'>
      <div className='fixed inset-0 bg-black opacity-50' onClick={onClose} />

      <div
        className={`relative z-50 p-8 bg-white rounded shadow-md ${modalSizeClass} ${modalContentStyle}`}
      >
        <span
          className={`absolute text-gray-600 cursor-pointer top-4 right-4 ${closeIconSize}`}
          onClick={onClose}
        >
          &times;
        </span>

        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
