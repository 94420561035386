import React from "react";
import gift from "../../../asset/gift.svg";
import award from "../../../asset/award.svg";
// import user from '../../../asset/user.svg';
import { ReactSVG } from "react-svg";

interface CelebrationsProps {
  name: string;
  email: string;
  dateOfBirth?: string;
  staffId: string;
  employmentDate?: string;
  image: string;
}

interface Props {
  userData: CelebrationsProps;
}

function generateRandomColor(): string {
  const r: number = Math.floor(Math.random() * 256);
  const g: number = Math.floor(Math.random() * 256);
  const b: number = Math.floor(Math.random() * 256);
  const color: string =
    "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  return color;
}

function getRandomTextColor(backgroundColor: string): string {
  const hex: string = backgroundColor.replace("#", "");
  const bigint: number = parseInt(hex, 16);
  const r: number = (bigint >> 16) & 255;
  const g: number = (bigint >> 8) & 255;
  const b: number = bigint & 255;
  const luminance: number = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? "#000000" : "#ffffff";
}

function IndividualCelebrations({ userData }: Props) {
  const background: string = generateRandomColor();
  const text: string = getRandomTextColor(background);
  const birthDateStr = userData?.dateOfBirth;
  let formattedDate = "";

  if (birthDateStr) {
    const dateObj = new Date(birthDateStr);

    if (!isNaN(dateObj.getTime())) {
      const month = dateObj.toLocaleDateString(undefined, { month: "long" });
      const day = dateObj.toLocaleDateString(undefined, { day: "numeric" });

      formattedDate = `${month} ${day}`;
    } else {
    }
  } else {
  }
  function calculateTimeSince(dateStr: string): {
    years: number;
    months: number;
  } {
    const today: Date = new Date();
    const date: Date = new Date(dateStr);
    let years: number = today.getFullYear() - date.getFullYear();
    let months: number = today.getMonth() - date.getMonth();

    if (today.getDate() < date.getDate()) {
      months -= 1;
    }
    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return { years, months };
  }
  const employmentDateStr = userData?.employmentDate;
  let employedDate = "";

  if (employmentDateStr) {
    const { years, months } = calculateTimeSince(employmentDateStr);
    employedDate = `${years} years, ${months} months`;
  }
  return (
    <div>
      <div>
        {userData.dateOfBirth ? (
          <div className="flex justify-between border-b border-[#EAECF0] py-3 px-2">
            <div className="flex gap-[10px]">
              <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full">
                {userData.image && userData.image.length > 1 ? (
                  <div
                    className="overflow-hidden border border-transparent rounded-full"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      src={userData.image}
                      alt="User"
                      className="rounded-full"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="flex items-center justify-center w-12 h-12 rounded-full"
                    style={{ backgroundColor: background, color: text }}
                  >
                    {userData.name
                      .split(" ")
                      .slice(0, 2)
                      .map((word) => word[0])
                      .join("")}
                  </div>
                )}
              </div>

              <div>
                <p className=" text-greenDarker">{userData?.name}</p>
                <div>
                  <p className="text-sm text-[#667085]">
                    <span>{formattedDate}</span>
                    <span>- Wishing you a happy Birthday 🎉</span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <ReactSVG src={gift} />
            </div>
          </div>
        ) : userData.employmentDate ? (
          <div className="flex justify-between border-b border-[#EAECF0] py-3 px-2">
            <div className="flex gap-[10px]">
              <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full">
                {userData.image && userData.image.length > 1 ? (
                  <div
                    className="overflow-hidden border border-transparent rounded-full"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      src={userData.image}
                      alt="User"
                      className="rounded-full"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="flex items-center justify-center w-12 h-12 rounded-full"
                    style={{ backgroundColor: background, color: text }}
                  >
                    {userData.name
                      .split(" ")
                      .slice(0, 2)
                      .map((word) => word[0])
                      .join("")}
                  </div>
                )}
              </div>

              <div>
                <p className=" text-greenDarker">{userData?.name}</p>
                <div>
                  <p className="text-sm text-[#667085]">
                    <span>{employedDate}</span>
                    <span>- Happy work Anniversary 🎉</span>
                  </p>
                </div>
              </div>
            </div>

            <div>
              <ReactSVG src={award} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default IndividualCelebrations;

//TODO: add modal for expand and view more that shows all the birthdays and anniversary
