import { Employee } from '../../utilities/types/types';

class Auth {
  /**
   * De-authenticate a user.
   * @description - Removes a token from localStorage.
   */
  static deAuthenticateUser(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

   /**
   * Authenticate an organization.
   * @description Save organization data in local storage.
   * @param data - Authentication data containing organization data.
   */
   static authenticateOrg = () => {
    try {
      const { hostname } = new URL(window.location.href);
      const parts = hostname.split('.');
      
      let organization = null; // Initialize organization variable
  
      if (parts.length >= 2) {
        organization = parts[0];
      }
  
      // Check if organization is not null before storing in localStorage
      if (organization !== null) {
        localStorage.setItem("organization", organization);
      } else {
        console.error("Unable to determine organization from the hostname.");
      }
    } catch (error) {
      console.error("Error while authenticating organization:", error);
    }
  }
  
  /**
   * Save current user
   * @description - Save user data and token string in local storage
   */
  static storeUser(user: Employee): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  /**
   * Authenticate a user
   * @description - Save user data and token string in local storage
   */
  static authenticateUser(data: any): void {
    localStorage.setItem('token', data.data.token);
    localStorage.setItem('user', JSON.stringify(data.data.data));

    // CrossDomainStorage.sendMessage(data)
  }

  /**
   * Check if a user is authenticated
   * @returns {boolean}
   * @description - check if a token is saved in Local Storage
   */
  static isUserAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    return true;
  }

  /**
   * Retrieve token from local storage
   * @returns {boolean}
   */
  static getToken(): string | boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return token;
    } else {
      return false;
    }
  }
    /**
   * Retrieve organization from local storage.
   * @returns {string | boolean} User organization if available, false otherwise.
   */
    static getOrganization(): string | boolean {
      try {
        const organization = localStorage.getItem("organization");
        return organization || false;
      } catch (error) {
        console.error("Error while retrieving organization:", error);
        return false;
      }
    }
}

export default Auth;
