import React, { ChangeEvent, useRef } from "react";

type ImageUploadProps = {
  selectedImages: File[];
  onImageUpload: (files: File[]) => void;
};

const ImageUploadCom: React.FC<ImageUploadProps> = ({
  selectedImages,
  onImageUpload,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      return;
    }

    const newSelectedImages = Array.from(files).map(
      (file) => new File([file], file.name)
    );
    onImageUpload([...selectedImages, ...newSelectedImages]);

    // Optionally, you can reset the file input if needed
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDeleteImage = (index: number) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    onImageUpload(updatedImages);
  };

  return (
    <div className="flex flex-row-reverse items-center justify-center gap-3 p-4 mt-4">
      <p className="text-sm text-red-600">Maximum upload file size: 1MB</p>
      <div className="flex items-center justify-center gap-2">
        <button
          className="px-4 py-2 gap-2 border border-[#D0D5DD] rounded-lg cursor-pointer"
          onClick={() => fileInputRef.current?.click()}
          type="button"
        >
          <div className="flex gap-2 text-greenDark ">
            {/* <ReactSVG src={clip} /> */}
            Attach Receipt
          </div>
        </button>
        <input
          type="file"
          multiple
          onChange={handleImageChange}
          ref={fileInputRef}
          className="hidden"
        />
      </div>

      <div className="flex flex-wrap items-center justify-center gap-3">
        {selectedImages.map((selectedImage, index) => (
          <div key={index} className="relative m-2">
            <img
              src={URL.createObjectURL(selectedImage)}
              alt={`Preview`}
              className="h-auto max-w-full"
              style={{ width: "100px", height: "100px" }}
            />
            <button
              onClick={() => handleDeleteImage(index)}
              className="absolute px-2 py-1 font-medium text-white transform -translate-x-1/2 -translate-y-1/2 bg-red-500 rounded hover:bg-red-700 top-1/2 left-1/2"
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUploadCom;
