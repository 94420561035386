/* eslint-disable jsx-a11y/anchor-is-valid */
import UserCardsHeader from "../../utilities/headers/userCardsHeader";
import link from "../../asset/link.svg";

function CompanyLinks() {
  return (
    <div className="w-[35%]  p-6 my-3 border border-solid rounded-lg">
      <UserCardsHeader icon={link} title="Company Links" />
      <div className="font-normal ">
        <h3 className="py-2 text-greenDark">Company</h3>
        <a href="#" className="text-sm">
          {" "}
          Company Website
        </a>
      </div>
      {/* <div className='text-xs font-normal'>
        <h3 className='py-2 text-greenDark'>Benefit</h3>
        <p>401k</p>
        <p>Health</p>
        <p>Vision</p>
        <p>Dental</p>
      </div>
      <div className='text-xs font-normal'>
        <h3 className='py-2 text-greenDark'>Help Request</h3>
        <p>IT Help Request</p>
      </div> */}
    </div>
  );
}

export default CompanyLinks;
