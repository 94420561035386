import { useEffect, useState } from "react";
import TitleSection from "../../../utilities/headers/titleSection";
import { ExpenseId } from "../../../utilities/types/types";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import { getOne } from "../../../redux/slices/expenses/getOneExpense";
import Comms from "./comms";
import Modal from "../../../utilities/components/modal";
import DisabledInput from "../../../utilities/components/disabledInput";
import { sendMessageForm } from "../../../redux/slices/expenses/sendMessage";
import {
  getActivities,
  setActivity,
} from "../../../redux/slices/expenses/activity";

function ViewExpenseForm() {
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | File>("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleImageClick = (image: string | File) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const { expenseId } = useParams<ExpenseId>();
  const dispatch = useAppDispatch();
  const validExpense = expenseId || "";
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getOne(validExpense));
    dispatch(getActivities(validExpense)).then((response) => {
      dispatch(setActivity(response.payload));
    });
  }, [dispatch, validExpense]);

  const expense = useAppSelector((state) => state.oneExpense.oneExpense);
  const activity = useAppSelector((state) => state.activity.activity);

  const imagePreviews = expense?.file || [];

  const createdAtDate = new Date(expense?.createdAt || "");
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentDate.getTime() - createdAtDate.getTime();

  // Convert milliseconds to days
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  const getFormattedDate = (dateString: string | undefined) =>
    dateString ? new Date(dateString).toLocaleDateString() : "";

  const handleSubmit = (editorHtml: string, expenseId: string) => {
    dispatch(sendMessageForm({ data: { sendMessage: editorHtml }, expenseId }));
  };

  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/40">
          {/* <img src={loader} alt='' width={800} height={800} /> */}
        </div>
      )}
      <div>
        <TitleSection
          subtitle={`Last edit ${daysAgo} days ago`}
          expenseType={
            expense?.status
              ? expense.status.charAt(0).toUpperCase() + expense.status.slice(1)
              : ""
          }
          onCancelButtonClick={handleCancelClick}
          grayButtonText="Cancel"
        />
      </div>
      <div className="text-greyDark">
        <div className="flex gap-10 mb-4">
          <DisabledInput
            label="Description"
            value={expense?.description || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />

          <DisabledInput
            label="Category"
            showDropdown
            value={expense?.category || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />
        </div>

        <div className="flex gap-10 mb-4">
          <DisabledInput
            label="Expense Date"
            showDropdown
            value={getFormattedDate(expense?.expenseDate)}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />
          <DisabledInput
            label="Amount"
            value={expense?.amount || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />
        </div>
        <div className="flex gap-10 mb-4">
          <DisabledInput
            label="Paid By"
            showDropdown
            value={expense?.paidBy || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />
          <DisabledInput
            label="Included Taxes(%)"
            value={expense?.tax || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />
        </div>
        <div className="flex gap-10 mb-4">
          <div>
            {imagePreviews.length > 0 && (
              <div className="flex gap-2 mt-4">
                {imagePreviews.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={typeof image === "string" ? image : ""}
                      alt={`Image ${index + 1}`}
                      className="object-contain max-h-40"
                    />
                    <button
                      className="absolute px-2 py-1 text-white transition-opacity duration-300 transform -translate-x-1/2 -translate-y-1/2 rounded-md opacity-0 bg-greenDark top-1/2 left-1/2 hover:opacity-100"
                      onClick={() => handleImageClick(image)}
                    >
                      View
                    </button>
                  </div>
                ))}
              </div>
            )}
            <Modal
              isOpen={modalIsOpen}
              onClose={() => setModalIsOpen(false)}
              size="fit"
            >
              <div className="">
                {selectedImage && (
                  <div className="pt-[5%]">
                    <img
                      src={
                        typeof selectedImage === "string" ? selectedImage : ""
                      }
                      alt="Selected Image"
                      className="object-contain max-h-80"
                    />
                  </div>
                )}
              </div>
            </Modal>
          </div>
        </div>
        <DisabledInput
          label="Notes"
          value={expense?.notes || ""}
          className="w-full p-2 mb-5 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
      </div>
      <Comms
        validId={validExpense}
        activity={activity}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default ViewExpenseForm;
