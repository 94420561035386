import React from 'react';
import Select from 'react-select';
import { useField, FieldAttributes } from 'formik';

interface CustomSelectProps extends FieldAttributes<any> {
  label?: string;
  options: { value: string; label: string }[];
  isMulti?: boolean;
  onChange?: (value: any) => void;
  disabled?: boolean;
  wrapperClassName?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  options,
  isMulti = true,
  onChange,
  wrapperClassName = 'pt-1',
  disabled = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: meta.touched && meta.error ? 'red' : 'initial',
    }),
  };

  const handleChange = (selectedOption: any) => {
    const selectedValues = isMulti
      ? (selectedOption as any[] | null)?.map((option: any) => option.value)
      : selectedOption?.value;
    helpers.setValue(selectedValues || (isMulti ? [] : ''));
    if (onChange) {
      onChange(selectedValues);
    }
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  const selectedOptions = options?.filter((option) =>
    field.value && isMulti
      ? field.value.includes(option.value)
      : option.value === field.value
  );
  return (
    <div>
      {label && (
        <label className='text-sm ' htmlFor={field.name}>
          {label}
        </label>
      )}
      <div className={wrapperClassName}>
        <Select
          {...field}
          {...props}
          options={options}
          isMulti={isMulti}
          onBlur={handleBlur}
          onChange={handleChange}
          value={selectedOptions}
          styles={customStyles}
          isDisabled={disabled}
        />
      </div>
      {meta.touched && meta.error ? (
        <div style={{ color: 'red' }}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CustomSelect;
