import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = Yup.object({
//   gender: Yup.string().required('Please select an option'),
//   workPhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
//   alternativeEmail: Yup.string()
//     .required('Email is required')
//     .email('Email is invalid'),
//   phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
//   dOB: Yup.date().required('Date of Birth is required'),
//   city: Yup.string().required('Please select an option'),
//   country: Yup.string().required('Country is required'),
//   state: Yup.string().required('Please select an option'),
//   zipCode: Yup.string().matches(/^[0-9]+$/, 'Invalid zip code'),
//   linkedIn: Yup.string().required('Link is required'),
//  facebook: Yup.string().required('Link is required'),
//  education: Yup.array().of(
//   Yup.object().shape({
//       college: Yup.string(),
//       degree: Yup.string(),
//       major: Yup.string(),
//       gpa: Yup.string(),
//       startDate: Yup.date(),
//       endDate: Yup.date(),
//   })
// ),twitter: Yup.string().required('Link is required'),

});
