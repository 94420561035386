import React, { useEffect, useState } from "react";
import { Button } from "../../utilities/button";
import { Formik } from "formik";
import * as Yup from "yup";
import { EmergencyValues } from "../../utilities/types/types";
import CustomInput from "../../utilities/forms/customInput";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import { emergencyContactCreation } from "../../redux/slices/auth/emergency";
import { toast } from "react-toastify";
import { ThreeCircles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  getEmergencyContact,
  setEmergencyContact,
} from "../../redux/slices/employes/emergencyContact";

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]*)|(\([0-9]{2,3}\)[ -]*)|([0-9]{2,4})[ -]*)*?[0-9]{3,4}?[ -]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  fullName: Yup.string()
    .min(3, "Full Name must be at least 3 characters")
    .required("Full Name is required"),
  homeAddress: Yup.string().required("Address is required"),
  relationship: Yup.string()
    .min(3, "Relationship must be at least 3 characters")
    .required("Relationship is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  workPhone: Yup.string().matches(
    phoneRegExp,
    "Work Phone number is not valid"
  ),
  homePhone: Yup.string().matches(
    phoneRegExp,
    "Home Phone number is not valid"
  ),
  mobilePhone: Yup.string().matches(
    phoneRegExp,
    "Mobile Phone number is not valid"
  ),
  primaryContact: Yup.boolean(),
  city: Yup.string().required("Please select an option"),
  country: Yup.string().required("Please select an option"),
  state: Yup.string().required("Please select an option"),
  zipCode: Yup.string().required("Zip Code is required"),
});

const EmergencyContactForm = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEmergencyContact()).then((response) => {
      dispatch(setEmergencyContact(response.payload));
    });
  }, [dispatch]);
  const user = useAppSelector((state) => state.emergencyContact.contact);
  const initialValues: EmergencyValues = {
    fullName: user?.fullName || "",
    relationship: user?.relationship || "",
    email: user?.email || "",
    workPhone: user?.workPhone || "",
    mobilePhone: user?.mobilePhone || "",
    homePhone: user?.homePhone || "",
    homeAddress: user?.homeAddress || "",
    primaryContact: user?.primaryContact || false,
    city: user?.city || "",
    country: user?.country || "",
    state: user?.state || "",
    zipCode: user?.zipCode || "",
  };

  const handleSubmit = async (data: EmergencyValues) => {
    // Handle form submission here
    setLoading(true);

    try {
      const res = await dispatch(emergencyContactCreation(data)); // Dispatch the login action with the form data
      setLoading(false);
      toast.success("Emergency Contact updated succesfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: "#00989B", color: "white" },
      });
      // resetForm()
    } catch (error: any) {
      setLoading(false);

      if (error?.response?.status === 400) {
        toast.error("Bad request. Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      }
    }
  };
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(-1);
  };
  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <ThreeCircles
            height="200"
            width="200"
            color="#15AFB2"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>
      )}
      <h3 className="pb-8 text-lg font-medium">Emergency Information</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="flex gap-10">
              <CustomInput
                id="fullName"
                label="Full Name"
                type="text"
                name="fullName"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={formik.errors.fullName}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Enter Full Name"
              />
              <CustomInput
                id="relationship"
                label="Relationship"
                type="text"
                name="relationship"
                value={formik.values.relationship}
                onChange={formik.handleChange}
                error={formik.errors.relationship}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 500 w-[400px] lg:w-[500px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Enter Relationship"
              />
            </div>
            <div className="flex gap-10">
              <CustomInput
                id="homePhone"
                label="Home Phone"
                type="tel"
                name="homePhone"
                value={formik.values.homePhone}
                onChange={formik.handleChange}
                error={formik.errors.homePhone}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Enter Home Phone"
              />
              <CustomInput
                id="mobilePhone"
                label="Mobile Phone"
                type="tel"
                name="mobilePhone"
                value={formik.values.mobilePhone}
                onChange={formik.handleChange}
                error={formik.errors.mobilePhone}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Enter Mobile Phone"
              />
            </div>
            <div className="flex gap-10">
              <CustomInput
                id="workPhone"
                label="Work Phone"
                type="tel"
                name="workPhone"
                value={formik.values.workPhone}
                onChange={formik.handleChange}
                error={formik.errors.workPhone}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Enter Work Phone"
              />
              <CustomInput
                id="email"
                label="Emergency Email Address"
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Enter your Emergency Email Address"
              />
            </div>

            <div className="flex gap-10">
              <CustomInput
                id="homeAddress"
                label="Home Address"
                type="text"
                name="homeAddress"
                value={formik.values.homeAddress}
                onChange={formik.handleChange}
                error={formik.errors.homeAddress}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Enter your Home Address"
              />
              <CustomInput
                id="city"
                label="City"
                type="text"
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Select city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.errors.city}
                options={["red", "blue", "green"]}
              />
            </div>
            <div className="flex gap-10">
              <CustomInput
                id="country"
                label="Country"
                type="text"
                name="country"
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Select Country"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.errors.country}
                options={["red", "blue", "green"]}
              />
              <div className="flex gap-10">
                <CustomInput
                  id="state"
                  label="State"
                  type="text"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  error={formik.errors.state}
                  options={["red", "blue", "green"]} // Pass the list of options
                  className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[150px] xl:w-[230px]"
                  wrapperClassName=""
                  placeholder="Select State "
                />
                <CustomInput
                  id="zipCode"
                  label="ZipCode"
                  type="number"
                  name="zipCode"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  error={formik.errors.zipCode}
                  className=" border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[150px] xl:w-[230px]"
                  wrapperClassName=""
                  placeholder=""
                />
              </div>
            </div>

            <div className="py-5">
              <hr />
            </div>
            <div className="flex justify-end pb-7">
              <Button
                type="reset"
                className="border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className={`border ${
                  formik.isValid
                    ? "text-white bg-greenDark"
                    : "text-white bg-greenWhite"
                } mr-4 rounded-lg px-[18px] py-[10px] text-left`}
              >
                Save changes
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EmergencyContactForm;
