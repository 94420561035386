// src/features/onboardingFormSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Leave_API from '../../../api/leaveService';

export const createNewLeave = createAsyncThunk(
  'create-leave',
  async (data: any, thunkAPI) => {
    try {
      const response = await Leave_API.create(data);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const createLeaveSlice = createSlice({
  name: 'createLeave',
  initialState: {
    formData: {
      leaveType: "",
      endDate: "",
      startDate: "",
      duration: "",
      backstop: "",
      notes: "",
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        leaveType: "",
        endDate: "",
        startDate: "",
        duration: "",
        backstop: "",
        notes: "",
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } = createLeaveSlice.actions;

export default createLeaveSlice.reducer;
export type RootState = ReturnType<typeof createLeaveSlice.reducer>;
