import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Birthdays } from '../../../utilities/types/types';
import Auth_API from '../../../api/authService/authservice';

interface birthdayState {
  birthday: Birthdays[];
}

// Create an async thunk to fetch employee data
export const getBirthday = createAsyncThunk('birthday', async () => {
  try {
    const response = await Auth_API.birthday();
    return response.data.sortedBirthdays;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const birthdaySlice = createSlice({
  name: 'birthday',
  initialState: {
    birthday: [],
  } as birthdayState,
  reducers: {
    setBirthday: (state, { payload }) => {
      state.birthday = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(ActiveEmployee.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setBirthday } = birthdaySlice.actions;

export default birthdaySlice.reducer;
