import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Auth_API from '../../../api/authService/authservice';
import { ProfileData } from '../../../utilities/types/types';

interface UserDataState {
  userData: ProfileData | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string | null;
}

export const getEmployee = createAsyncThunk('profileData', async () => {
  try {
    const response = await Auth_API.user();
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

const userSlice = createSlice({
  name: 'userData',
  initialState: {
    userData: null,
    loading: 'idle',
    error: null,
  } as UserDataState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployee.pending, (state) => {
        state.loading = 'pending';
        state.error = null;
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.userData = action.payload;
      })
      .addCase(getEmployee.rejected, (state, action) => {
        state.loading = 'failed';
        state.error =
          action.error.message ||
          'An error occurred while fetching user information.';
      });
  },
});
export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
