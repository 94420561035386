import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { Passwords } from "../../utilities/types/types";
import CustomInput from "../../utilities/forms/customInput";
import { Button } from "../../utilities/button";
import { toast } from "react-toastify";
// import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../redux/hook/authHook";
import { passwordUpdate } from "../../redux/slices/auth/updatePassword";

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  newPassword: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmNewPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword")], "Confirm Password does not match"),
});

const UserChangePasswordForm: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const initialValues: Passwords = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (data: Passwords) => {
    setLoading(true);

    try {
      const res = await dispatch(passwordUpdate(data));
      setLoading(false);

      // navigate('/user/dashboard');

      toast.success("Password updated succesfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: "#00989B", color: "white" },
      });
    } catch (error: any) {
      setLoading(false);

      if (error?.response?.status === 400) {
        toast.error("Bad request. Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      }
    }
  };

  return (
    <div>
      <h3 className="pb-8 text-lg font-medium">Change Password</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="flex gap-6">
              <CustomInput
                id="currentPassword"
                label="Current Password"
                type="text"
                name="currentPassword"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                error={formik.errors.currentPassword}
                className="border border-gray-400  rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
                wrapperClassName=""
                placeholder="Enter Current Password"
              />
              <CustomInput
                id="newPassword"
                label="New Password"
                type="text"
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={formik.errors.newPassword}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
                wrapperClassName=""
                placeholder="Enter Password"
              />
            </div>

            <div className="">
              <CustomInput
                id="confirmNewPassword"
                label="Confirm New Password"
                type="text"
                name="confirmNewPassword"
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                error={formik.errors.confirmNewPassword}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
                wrapperClassName=""
                placeholder="Enter Password"
              />
            </div>
            <div className="py-5">
              <hr />
            </div>
            <div className="flex justify-end">
              <Button
                type="submit"
                className="border border-transparent text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left"
              >
                Save changes
              </Button>
            </div>
          </form>
        )}
      </Formik>

      {/* Loader */}
      {loading && (
        <div className="loaderContainer">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default UserChangePasswordForm;
