import React, { useEffect, useState } from "react";
import { Button } from "../../utilities/button";
import { Formik } from "formik";
import { BioDataValues } from "../../utilities/types/types";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import { profileCreation } from "../../redux/slices/auth/createProfile";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeCircles } from "react-loader-spinner";
import {
  getEmployee,
  setUserData,
} from "../../redux/slices/employes/userDataSlice";
import { validationSchema } from "./biodata/validation";
import BasicInformation from "./biodata/basicInfo";
import Address from "./biodata/address";
import SocialMedia from "./biodata/socials";
import EducationHistory from "./biodata/education";

const BioData = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEmployee()).then((response) => {
      dispatch(setUserData(response.payload));
    });
  }, [dispatch]);
  const user = useAppSelector((state) => state.user.userData);
  const formatDate = (dateString: string | Date) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear().toString().slice(2); // Extract last two digits of the year
    return `${day}${month}${year}`;
  };
  const dOBDate = user?.dOB ? new Date(user.dOB) : new Date();

  const initialValues: BioDataValues = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    middleName: user?.middleName || "",
    staffId: user?.staffId || 0,
    email: user?.email || "",
    phone: user?.phone || "",
    dOB: dOBDate,
    gender: user?.gender || "",
    contractType: user?.employmentType || "",
    homeAddress: user?.homeAddress || "",
    city: user?.employee?.city || "",
    country: user?.employee?.country || "",
    state: user?.employee?.state || "",
    zipCode: user?.employee?.zipCode || "",
    homePhone: user?.employee?.homePhone || "",
    mobilePhone: user?.employee?.mobilePhone || "",
    workPhone: user?.employee?.workPhone || "",
    alternativeEmail: user?.employee?.alternativeEmail || "",
    linkedIn: user?.employee?.linkedIn || "",
    facebook: user?.employee?.facebook || "",
    twitter: user?.employee?.twitter || "",
    education: [
      {
        college: user?.employee?.education[0]?.college || "",
        degree: user?.employee?.education[0]?.degree || "",
        major: user?.employee?.education[0]?.major || "",
        gpa: user?.employee?.education[0]?.gpa || "",
        startDate: user?.employee?.education[0]?.startDate || new Date(),
        endDate: user?.employee?.education[0]?.endDate || new Date(),
      },
    ],
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (data: BioDataValues) => {
    setLoading(true);

    try {
      const res = await dispatch(profileCreation(data));
      setLoading(false);
      navigate("/user/dashboard");
      toast.success("Profile updated succesfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: "#00989B", color: "white" },
      });
    } catch (error: any) {
      setLoading(false);

      if (error?.response?.status === 400) {
        toast.error("Bad request. Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      }
    }
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <ThreeCircles
            height="200"
            width="200"
            color="#15AFB2"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>
      )}
      <h3 className="pb-8 text-lg font-medium">Basic Information</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <BasicInformation formik={formik} user={user} />
            <div className="py-5">
              <hr />
            </div>

            <Address formik={formik} />
            <div className="py-5">
              <hr />
            </div>
            <SocialMedia formik={formik} />
            <div className="py-5">
              <hr />
            </div>
            <EducationHistory formik={formik} user={user} />
            <div className="py-5">
              <hr />
            </div>
            <div className="flex justify-end pb-7">
              <Button
                type="reset"
                className="border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className={`border ${
                  formik.isValid
                    ? "text-white bg-greenDark"
                    : "text-white bg-greenWhite"
                } mr-4 rounded-lg px-[18px] py-[10px] text-left`}
              >
                Save changes
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default BioData;
