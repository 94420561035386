// import { data } from "../utilities/table/data/tableData";
import Tabs from "../../utilities/tabs";
import { useEffect, useState } from "react";
// import { useAppDispatch, useAppSelector } from '../../redux/hook/authHook';
import BioData from "./biodata";
import TimeOff from "../leaveManagement/timeOff";
// import Compensation from './compensation';
import JobInfo from "./jobInfo";
import EmergencyContactForm from "./emergencyContact";
import Profile from "../useroverview/profile";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import {
  getEmployee,
  setUserData,
} from "../../redux/slices/employes/userDataSlice";
import { ProfileData } from "../../utilities/types/types";
import Doc from "./docUpload";

function ProfileEditIndex() {
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEmployee()).then((response) => {
      dispatch(setUserData(response.payload));
    });
  }, [dispatch]);

  const user = useAppSelector((state) => state.user.userData) as ProfileData;

  return (
    <div>
      <Profile showIcons={false} user={user} />
      <div className="py-6">
        <Tabs
          tabs={["Personal", "Jobs", "Time Off", "Documents", "Emergency"]}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          activeClassName="text-grenDark border-b text-center px-4 py-2"
          inactiveClassName="px-4 py-2"
        />
      </div>
      <div>
        {activeTab === 1 && <BioData />}
        {activeTab === 2 && <JobInfo user={user} />}
        {activeTab === 3 && <TimeOff />}
        {activeTab === 4 && <Doc />}
        {activeTab === 5 && <EmergencyContactForm />}
      </div>
    </div>
  );
}

export default ProfileEditIndex;
