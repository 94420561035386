import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Payslip } from '../../utilities/types/types';
import Payslip_API from '../../api/payslipService';


interface PayslipState {
  payslip: Payslip[];
}

export const getPayslip = createAsyncThunk('payslip', async () => {
  try {
    const response = await Payslip_API.getPayslip();
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const payslipSlice = createSlice({
  name: 'payslip',
  initialState: {
    payslip: [],
  } as PayslipState,
  reducers: {
    setPayslip: (state, { payload }) => {
      state.payslip = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allExpense.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setPayslip } = payslipSlice.actions;

export default payslipSlice.reducer;