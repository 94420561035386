import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ExpenseId, SendMessageValues } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

export const sendMessageForm = createAsyncThunk(
  'sendMessage/sendMessageForm',
  async (
    { data, expenseId }: { data: SendMessageValues; expenseId: ExpenseId },
    thunkAPI
  ) => {
    try {
      const response = await Expense_API.sendMessageExpense(expenseId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const sendMessageSlice = createSlice({
  name: 'sendMessage',
  initialState: {
    sendMessage: '',
    error: null,
  },
  reducers: {
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendMessageForm.fulfilled, (state, action) => {
      // Handle successful message sending
      state.sendMessage = action.payload;
      state.error = null;
    });
    // builder.addCase(sendMessageForm.rejected, (state, action) => {
    //   // Handle rejection or error in sending message
    //   state.error = action.payload;
    // });
  },
});

export const { setErrors, resetForm } = sendMessageSlice.actions;

export default sendMessageSlice.reducer;
export type RootState = ReturnType<typeof sendMessageSlice.reducer>;
