import { data, datafooter } from "./data/data";
import { useState } from "react";
import "../../../../styles/sideBar.css";

type props = {
  active: () => void;
};

function MainInactive({ active }: props) {
  return (
    <div>
      <div className="grow">
        {data.admin.map((group, index) => (
          <div key={index} className="my-2">
            <div className="flex px-6 cursor-pointer pb-9" onClick={active}>
              <div className="">{group.name.icon}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="fixed bottom-0 bg-[#1d2939]">
        {datafooter.admin.map((group, index) => (
          <div key={index}>
            {group.items.map((item, index2) => (
              <div
                key={index2}
                className={`flex px-6 py-1 cursor-pointer ${item.className}`}
              >
                <div className="py-8 text-lg" onClick={active}>
                  {item.icon}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MainInactive;
