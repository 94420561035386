import {
  BioDataValues,
  email,
  EmergencyValues,
  Employee,
 
  Passwords,
  resetPasswordValue,
  SignInForm,
} from '../../utilities/types/types';
import axiosService from '../axios';

const API_URL = process.env.REACT_APP_API_URL;
const user: Employee | null = JSON.parse(localStorage.getItem('user')!);
const token = localStorage.getItem('token');

export default class Auth_API {
  static async login(data: SignInForm): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}login`,
    });
  }
  static async forgotPassword(data: email): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}requestToken`,
    });
  }
  static async resetPassword(
    data: resetPasswordValue,
    // token: string
  ): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}resetPassword/${token}`,
    });
  }
  static async createProfile(data: BioDataValues): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}add`,
    });
  }

  static async emergencyContact(data: EmergencyValues): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}contact/create`,
    });
  }

  static async user(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}`,
    });
  }

  static async birthday(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}birthday`,
    });
  }
  static async emergency(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}contact`,
    });
    
  } 
  static async logo(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}logo`,
    });
    
  } 
   static async newOnboard(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}recent-employees`,
    });
  }
  static async employementDate(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}employmentDate`,
    });
  }
  static logout = () => {
    localStorage.removeItem('user');
  };
  // static async updateProfile(data: AdminSignInForm): Promise<any> {
  //   return await axiosService({
  //     method: 'POST',
  //     data: data,
  //     url: `${API_URL}update`,
  //   });
  // }
  // static async uploadFile(data: FileValues): Promise<any> {
  //   return await axiosService({
  //     method: 'POST',
  //     data: data,
  //     url: `${API_URL}upload`,
  //   });

  static async uploadImage(data: FormData): Promise<any> {
   
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}upload`,
    });
  }
  
  static async updatePassword(data: Passwords): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      data: data,
      url: `${API_URL}updatePassword`,
    });
  }
}

//     axios.post(API_URL + "login", data).then((response) => {
//     if (response.data.accessToken) {
//       localStorage.setItem("user", JSON.stringify(response.data));
//     }

//     return response.data;
//   });
// };

// const authService = {
//   register,
//   login,
//   logout,
// };

// export default authService;
