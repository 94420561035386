import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Leave_API from '../../../api/leaveService';
import { LeaveType } from '../../../utilities/types/types';

interface LeaveCategoryState {
category: LeaveType[];
}

export const getLeaveCategory = createAsyncThunk('category', async () => {
  try {
    const response = await Leave_API.category();
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const categorySlice = createSlice({
  name: 'category',
  initialState: {
    category: [],
  } as LeaveCategoryState,
  reducers: {
    setLeaveCategory: (state, { payload }) => {
      state.category = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allExpense.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setLeaveCategory } = categorySlice.actions;

export default categorySlice.reducer;
