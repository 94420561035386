import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Auth_API from '../../../api/authService/authservice';
import { BioDataValues } from '../../../utilities/types/types';

export const profileCreation = createAsyncThunk(
  'createProfile',
  async (data: BioDataValues, thunkAPI) => {
    try {
      const response = await Auth_API.createProfile(data);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
// Replace 'YOUR_SLICE_NAME' with an appropriate name for your slice
const profileCreateSlice = createSlice({
  name: 'profileCreate',
  initialState: {
    formData: {
      firstName: '',
      lastName: '',
      middleName: '',
      staffId: 0,
      email: '',
      phone: '',
      dOB: '',
      gender: '',
      contractType: '',
      homeAddress: '',
      city: '',
      country: '',
      state: '',
      zipCode: '',
      homePhone: '',
      mobilePhone: '',
      workPhone: '',
      alternativeEmail: '',
      linkedIn: '',
      facebook: '',
      twitter: '',
      college: '',
      degree: '',
      major: '',
      gpa: '',
      startDate: '',
      endDate: '',
      college2: '',
      degree2: '',
      major2: '',
      gpa2: '',
      startDate2: '',
      endDate2: '',
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        firstName: '',
        lastName: '',
        middleName: '',
        staffId: 0,
        email: '',
        phone: '',
        dOB: '',
        gender: '',
        contractType: '',
        homeAddress: '',
        city: '',
        country: '',
        state: '',
        zipCode: '',
        homePhone: '',
        mobilePhone: '',
        workPhone: '',
        alternativeEmail: '',
        linkedIn: '',
        facebook: '',
        twitter: '',
        college: '',
        degree: '',
        major: '',
        gpa: '',
        startDate: '',
        endDate: '',
        college2: '',
        degree2: '',
        major2: '',
        gpa2: '',
        startDate2: '',
        endDate2: '',
      };
      state.error = null;
    },
  },
});

// Export actions
export const { setFormData, setErrors, resetForm } = profileCreateSlice.actions;

export default profileCreateSlice.reducer;
export type RootState = ReturnType<typeof profileCreateSlice.reducer>;
