import React from "react";
import arrow_right from "../../asset/chevron-right.svg";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { EmergencyValues, ProfileData } from "../../utilities/types/types";

interface OnboardingTaskProps {
  user: ProfileData;
  emergency: EmergencyValues;
}

// Type guard functions to check if personal info or emergency contact is filled
const isPersonalInfoFilled = (bioDataValues: Partial<ProfileData>): boolean => {
  return Object.values(bioDataValues).some((value) => {
    if (typeof value === "string" || typeof value === "number") {
      return value !== "" && value !== 0;
    }
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (value instanceof Date) {
      return !isNaN(value.getTime());
    }
    return value !== undefined;
  });
};

const isEmergencyContactFilled = (
  emergencyValues: Partial<EmergencyValues>
): boolean => {
  return Object.values(emergencyValues).some((value) => {
    if (typeof value === "string") {
      return value !== "";
    }
    if (typeof value === "number") {
      return value !== 0;
    }
    return value !== undefined;
  });
};

function OnboardingTask({ user, emergency }: OnboardingTaskProps) {
  // Derive bioDataValues from user
  const bioDataValues: Partial<ProfileData> = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    middleName: user?.middleName || "",
    staffId: user?.staffId || 0,
    email: user?.email || "",
    phone: user?.phone || "",
    dOB: user?.dOB || new Date(),
    gender: user?.gender || "",
    contractType: user?.employmentType || "",
    homeAddress: user?.homeAddress || "",
    city: user?.employee?.city || "",
    country: user?.employee?.country || "",
    state: user?.employee?.state || "",
    zipCode: user?.employee?.zipCode || "",
    homePhone: user?.employee?.homePhone || "",
    mobilePhone: user?.employee?.mobilePhone || "",
    workPhone: user?.employee?.workPhone || "",
    alternativeEmail: user?.employee?.alternativeEmail || "",
    linkedIn: user?.employee?.linkedIn || "",
    facebook: user?.employee?.facebook || "",
    twitter: user?.employee?.twitter || "",
    education: user?.employee?.education || [],
  };

  // Derive emergencyValues from emergency prop
  const emergencyValues: Partial<EmergencyValues> = {
    fullName: emergency?.fullName || "",
    relationship: emergency?.relationship || "",
    email: emergency?.email || "",
    workPhone: emergency?.workPhone || "",
    mobilePhone: emergency?.mobilePhone || "",
    homePhone: emergency?.homePhone || "",
    homeAddress: emergency?.homeAddress || "",
    city: emergency?.city || "",
    country: emergency?.country || "",
    state: emergency?.state || "",
    zipCode: emergency?.zipCode || "",
  };

  const showPersonalInfoTask = isPersonalInfoFilled(bioDataValues);
  const showEmergencyContactTask = isEmergencyContactFilled(emergencyValues);

  // If no tasks should be shown, return null
  if (!showPersonalInfoTask && !showEmergencyContactTask) {
    return null;
  }

  return (
    <div className="w-full mb-4">
      <div className="flex flex-col gap-5">
        <h2 className="border-b border-[#EAECF0] pb-3 text-xl">
          Onboarding Tasks
        </h2>

        {showPersonalInfoTask && (
          <div className="flex items-center justify-between p-6 border rounded-lg cursor-pointer">
            <p className="text-[18px] font-normal text-[#101828] tracking-wide">
              Update Personal Information
            </p>
            <Link to="/user/profile">
              <div className="flex items-center gap-4">
                <p className="text-lg font-medium text-greenDark">Start</p>
                <ReactSVG src={arrow_right} />
              </div>
            </Link>
          </div>
        )}
        <div className="flex items-center justify-between p-6 border rounded-lg cursor-pointer boorder-2">
          <p className="text-[18px] font-normal text-[#101828] tracking-wide">
            Upload Documents
          </p>
          <Link to="/user/profile">
            <div className="flex items-center gap-4">
              <p className="text-lg font-medium text-greenDark">Start</p>
              <ReactSVG src={arrow_right} />
            </div>
          </Link>
        </div>

        {showEmergencyContactTask && (
          <div className="flex items-center justify-between p-6 border rounded-lg cursor-pointer ">
            <p className="text-[18px] font-normal text-[#101828] tracking-wide">
              Update Emergency Contact
            </p>
            <Link to="/user/profile">
              <div className="flex items-center gap-4">
                <p className="text-lg font-medium text-greenDark">Start</p>
                <ReactSVG src={arrow_right} />
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default OnboardingTask;
