import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  LeaveId, SendMessageValues } from '../../../utilities/types/types';
import Leave_API from '../../../api/leaveService';


export const sendMessageForm = createAsyncThunk(
  'sendMessage/sendMessageForm',
  async (
    { data, leaveId }: { data: SendMessageValues; leaveId: LeaveId },
    thunkAPI
  ) => {
    try {
      const response = await Leave_API.sendMessageLeave(leaveId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const sendMessageSlice = createSlice({
  name: 'sendMessage',
  initialState: {
    sendMessage: '',
    error: null,
  },
  reducers: {
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendMessageForm.fulfilled, (state, action) => {
      // Handle successful message sending
      // state.sendMessage = action.payload;
      state.error = null;
    });
    // builder.addCase(sendMessageForm.rejected, (state, action) => {
    //   // Handle rejection or error in sending message
    //   state.error = action.payload;
    // });
  },
});

export const { setErrors, resetForm } = sendMessageSlice.actions;

export default sendMessageSlice.reducer;
export type RootState = ReturnType<typeof sendMessageSlice.reducer>;
