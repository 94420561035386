// import { ReactNode } from "react";
import Header from './header';
import { Outlet } from 'react-router-dom';
import Navbar from './sidebar';

// interface LayoutProps {
//   children: ReactNode;
// }

const Layout = () => {
  return (
    <>
      <div className='flex w-screen font-switzer text-grayText '>
        <div className='flex-1 '>
          <Navbar />
        </div>
        <div className='w-100% lg:w-full px-8 h-screen overflow-scroll'>
          <Header />
          <main>
            {/* {children} */}
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
