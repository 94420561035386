import React, { useState } from "react";
import * as Yup from "yup";
import "../../styles/auth.css";
import CustomInput from "../utilities/forms/customInput";
import { Formik } from "formik";
import { toast } from "react-toastify"; // Import the ToastContainer
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { login } from "../redux/slices/auth/authSlice";
import { SignInForm } from "../utilities/types/types";
import { useAppDispatch } from "../redux/hook/authHook";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
});

const LoginForm: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const initialValues: SignInForm = {
    email: "",
    password: "",
    // remember: false,
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleSubmit = async (data: SignInForm) => {
    setLoading(true);

    try {
      const res = await dispatch(login(data));
      setLoading(false);
      const userRole = res?.payload?.data?.data?.role;

      if (
        res?.payload?.statusCode === 404 ||
        res?.payload?.statusCode === 400
      ) {
        toast.error("User not found", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else if (
        res?.payload?.statusCode === 401 ||
        res?.payload?.statusCode === 500
      ) {
        toast.error(
          "Invalid login credentials. Please check your email and password.",
          {
            position: "top-center",
            autoClose: 3000,
            theme: "colored",
            style: { backgroundColor: "#F04438", color: "white" },
          }
        );
      } else if (
        userRole === "user" ||
        userRole === "admin" ||
        userRole === "superAdmin"
      ) {
        navigate("/user/dashboard");
        toast.success("Login successful", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
      }
    } catch (error: any) {
      setLoading(false);

      if (error?.response?.status === 400) {
        toast.error("Bad request. Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#D92D20", color: "white" },
        });
      }
    }
  };

  return (
    <div className="registerForm">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="formGroup">
              <CustomInput
                id="email"
                label="Staff ID/ Email"
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
                wrapperClassName=""
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px]"
                placeholder="Enter Professional Email"
              />
            </div>

            <div className="formGroup">
              <div className="">
                <CustomInput
                  id="password"
                  label="Password"
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  className="border-transparent  rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[368px]"
                  wrapperClassName="inputWrapper"
                  placeholder="Enter Password"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="formGroup">
                {/* <CustomInput
                  id="remember"
                  label="Remember this device for 30days"
                  type="checkbox"
                  name="remember"
                  value={formik.values.remember}
                  onChange={formik.handleChange}
                  error={formik.errors.remember}
                  wrapperClassName=""
                  className=""
                  placeholder=""
                /> */}
              </div>
              <div>
                <Link to="/forgotPassword" className="textColor">
                  Forgot Password
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className={`${
                  loading
                    ? "bg-[#9FE6E8] font-medium text-white text-base px-48 py-2 rounded-lg my-6"
                    : !formik.isValid
                    ? "bg-[#9FE6E8] font-medium text-white text-base px-[10.5rem] py-2 rounded-lg my-6"
                    : "bg-greenDark  font-medium text-white text-base px-[10.5rem] py-2 rounded-lg my-6"
                }`}
              >
                {loading ? (
                  <Bars
                    height={20}
                    width={20}
                    color="#fff"
                    ariaLabel="bars-loading"
                  />
                ) : (
                  "LOG IN"
                )}
              </button>
              {/* <div className='lineDiv'>
                <div className='lineLeft'></div>
                <p>OR</p>
                <div className='lineRight'></div>
              </div>
              <button className='btnMod'>SIGN IN WITH GOOGLE</button> */}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
