import React from 'react';
import { EmptyState } from './emptyState';

function NotSubscribed() {
  return (
    <div>
      <EmptyState
        title='UNSUBSCRIBED'
        subtitle={''}
        message='Kindly reach out to your admin'
        icon={''}
        link='/user/dashboard'
        actionLabel='Back to dashboard'
        status={'error'}
      />
    </div>
  );
}

export default NotSubscribed;
