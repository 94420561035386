import { Leave, LeaveId, SendMessageValues } from "../utilities/types/types";
import axiosService from "./axios";
import uploadAxiosService from "./axiosUpload";

const API_URL = process.env.REACT_APP_API_URL;

export default class Leave_API {
  static async create(data: any): Promise<any> {
    return await uploadAxiosService({
      method: "POST",
      data: data,
      url: `${API_URL}leave/create`,
    });
  }
  static async getAllLeave(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}leave`,
    });
  }
  static async oneLeave(LeaveId: LeaveId): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}leave/${LeaveId}`,
    });
  }
  static async getApprovedLeave(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}`,
      params: {
        status: "approved",
      },
    });
  }
  static async analytics(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}leave/leave-type/analytics`,
    });
  }
  static async sendMessageLeave(
    LeaveId: LeaveId,
    data: SendMessageValues
  ): Promise<any> {
    return await axiosService({
      method: "PATCH",
      url: `${API_URL}leave/message/${LeaveId}`,
      data: data,
    });
  }

  // static async editLeave(): Promise<any> {
  //   return await axiosService({
  //     method: 'PATCH',
  //     url: `${API_URL}update/${LeaveId}`,
  //   });
  // }
  static async updateLeave(data: Leave, LeaveId: LeaveId): Promise<any> {
    return await uploadAxiosService({
      method: "PATCH",
      data: data,
      url: `${API_URL}update/${LeaveId}`,
    });
  }
  static async category(): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}leave/get/leave-type`,
    });
  }

  static async getActivity(LeaveId: LeaveId): Promise<any> {
    return await axiosService({
      method: "GET",
      url: `${API_URL}leave/get/activity/${LeaveId}`,
    });
  }
}
