import { useState, ChangeEvent, useRef } from "react";
import { ReactSVG } from "react-svg";
import { ProfileData } from "../../utilities/types/types";
import uploadIcon from "../../asset/upload-cloud.svg";
import profileIcon from "../../asset/Avatar.svg";
import { useAppDispatch } from "../../redux/hook/authHook";
import { uploadImage } from "../../redux/slices/auth/imageSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
interface DPProps {
  showIcons: boolean;
  user: ProfileData | null;
}

function DP({ showIcons, user }: DPProps) {
  const [image, setImage] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const profileImage = user?.profileImage;
  const isDefaultImage = !profileImage;
  const dispatch = useAppDispatch();

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setSelectedImage(file || null);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setImage(reader.result as string);
      reader.readAsDataURL(file);
    } else {
      setImage(null);
    }
  };
  const navigate = useNavigate();

  const handleUpload = async () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);

      try {
        const response = await dispatch(uploadImage(formData));
        toast.success("Successfully Uploaded", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
        window.location.reload();
      } catch (error) {
        // Handle errors if necessary
      }
    }
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="profile-image-container">
      <div className="image-preview-container">
        <div className="relative rounded-full">
          <div className="w-32 h-25">
            <img
              src={isDefaultImage ? image || profileIcon : profileImage}
              alt={isDefaultImage ? "Profile Preview" : "uploaded"}
              className="object-cover w-24 h-24 rounded-full"
            />
          </div>
          <button className={image ? "" : "hidden"} onClick={handleUpload}>
            Upload
          </button>
          <div
            className={
              !showIcons
                ? "bg-[#E0F6F6] h-[30px] w-[30px] flex justify-center items-center rounded-2xl absolute left-[60px] top-[50px] lg:top-[70px] cursor-pointer"
                : "hidden"
            }
          >
            <ReactSVG
              src={uploadIcon}
              onClick={handleSelect}
              width={100}
              height={100}
            />
          </div>
        </div>
      </div>

      <input
        type="file"
        id="DPUpload"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
    </div>
  );
}

export default DP;
