import React, { FC } from 'react';

interface CircularProgressBarProps {
  percentage: number;
  text?: number;
}

const CircularProgressBar: FC<CircularProgressBarProps> = ({
  percentage,
  text,
}) => {
  const radius = 40;
  const circumference = 2 * Math.PI * radius;

  const dashOffset = circumference - (percentage / 100) * circumference;

  const textX = 50;
  const textY = 50;

  return (
    <svg className='w-24 h-28'>
      <circle
        className='stroke-current text-greenDark'
        strokeWidth='5'
        fill='transparent'
        r={radius}
        cx='50'
        cy='50'
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: dashOffset,
        }}
      />
      {text && (
        <text
          x={textX}
          y={textY}
          dominantBaseline='middle'
          textAnchor='middle'
          fill='black'
        >
          {text}%
        </text>
      )}
    </svg>
  );
};

export default CircularProgressBar;
