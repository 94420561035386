import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import upload from '../../../asset/cloudUpload.svg';
import file from '../../../asset/file.svg';
import del from '../../../asset/trash.svg';

interface FileUploadSectionProps {
  isUploading: boolean;
  handleUpload: () => void;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedFile: File | null;
  handleDelete: () => void;
  setProgress: (progress: number) => void;
}

const FileUploadSection: React.FC<FileUploadSectionProps> = ({
  isUploading,
  handleUpload,
  handleFileChange,
  selectedFile,
  handleDelete,
  setProgress,
}) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Simulate progress increment until 100%
      if (uploadProgress < 100 && isUploading) {
        setUploadProgress((prevProgress) => prevProgress + 1);
        setProgress(uploadProgress);
      } else {
        clearInterval(intervalId);
      }
    }, 50); // Adjust the interval as needed

    return () => {
      clearInterval(intervalId);
    };
  }, [isUploading, uploadProgress]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className=''>
      <div className='max-w-[460px] px-3 py-8 my-4 border rounded-lg border-greyWhite'>
        {selectedFile ? (
          <div>
            {selectedFile && (
              <div className='flex items-center gap-4'>
                <div className='p-2 rounded-2xl bg-[#C6F2F3]'>
                  <ReactSVG src={file} />
                </div>
                <div className='flex gap-32'>
                  <div>
                    <p>File Name: {selectedFile.name}</p>
                    <p className='text-xs'>{selectedFile.size}</p>
                  </div>
                  <ReactSVG
                    src={del}
                    onClick={handleDelete}
                    className='hover:cursor-pointer'
                  />
                </div>
              </div>
            )}

            {isUploading && (
              <div className='pt-1 '>
                <div className='flex items-center justify-between mb-2'>
                  <div className='font-medium text-white'>
                    {uploadProgress === 99 ? (
                      <span className='inline-block px-2 py-1 uppercase rounded-full bg-greenDark'>
                        Done
                      </span>
                    ) : (
                      <span className='inline-block px-2 py-1 uppercase rounded-full bg-greenDark'>
                        In Progress
                      </span>
                    )}
                  </div>
                  <div className='text-right'>
                    <span className='inline-block text-xs font-semibold text-greenDark'>
                      {`${uploadProgress}%`}
                    </span>
                  </div>
                </div>
                <div className='flex items-center justify-start mb-2'>
                  <div className='w-[95%] mx-auto bg-blue-200 rounded-full'>
                    <div
                      className='w-full py-1 text-xs leading-none rounded-full bg-greenDark'
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className='flex flex-col items-center justify-center w-full h-[50px] py-8'>
            <div className='mb-4'>
              <ReactSVG src={upload} />
            </div>
            <p className='text-lg font-normal text-center'>
              <span
                className='cursor-pointer text-greenDark'
                onClick={handleSelect}
              >
                Click to upload
              </span>{' '}
              or drag and drop
            </p>
            <p className='text-sm font-light text-center'>
              pdf, docx, doc or txt (max. 800x400px)
            </p>
          </div>
        )}
      </div>

      <div className='flex items-center space-x-2'>
        <button
          onClick={handleUpload}
          className={`text-white bg-greenDark  rounded-lg px-[18px] py-[10px]  focus:outline-none cursor-pointer
            }`}
        >
          Upload
        </button>

        <input
          id='file-input'
          type='file'
          accept='.pdf,.doc,.docx'
          className='hidden'
          onChange={handleFileChange}
          disabled={isUploading}
          ref={fileInputRef}
        />
      </div>
    </div>
  );
};

export default FileUploadSection;
