import React from 'react';
import { ReactSVG } from 'react-svg';
import expand from '../..//asset/expand.svg';

interface HeaderProps {
  icon: string;
  title: string;
}
function UserCardsHeader({ icon, title }: HeaderProps) {
  return (
    <div className='flex justify-between items-center pb-4 border-b border-[#EAECF0] '>
      <div className='flex gap-4'>
        <ReactSVG src={icon} />
        <p className='text-lg font-medium text-greenDark'>{title}</p>
      </div>
      <div>
        <ReactSVG src={expand} />
      </div>
    </div>
  );
}

export default UserCardsHeader;
