import React, { useEffect, useState } from "react";
import { ButtonIconClick } from "../../../utilities/button";
import Modal from "../../../utilities/components/modal";
import DocumentUpload from "./docUpload";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import { getDoc, setFile } from "../../../redux/slices/getFiles";
import DocumentTable from "./documentTable";
import upload from "../../../asset/upload.svg";

function Doc() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const docs = useAppSelector((state) => state.file.file);

  useEffect(() => {
    dispatch(getDoc()).then((response: any) => {
      dispatch(setFile(response.payload));
    });
  }, [dispatch]);

  const rowCount = docs.length;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {" "}
      <div className="flex justify-between">
        <div>
          <h4 className="text-xl font-normal">Documents Upload</h4>
        </div>
        <div className="flex gap-2">
          <div className="flex justify-center h-10 px-3 border rounded-lg">
            <div className="pr-[10.5px] py-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z"
                  stroke="#667085"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <input
              type="search"
              placeholder="Search for documents "
              size={30}
              className="text-black bg-transparent outline-none border-hidden "
            />
          </div>
          <ButtonIconClick
            className="bg-greenDark text-white rounded-lg px-[18px] py-[10px]"
            src={upload}
            text="Upload"
            onClick={openModal}
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="w-[480px] flex flex-col items-start gap-8">
          <div className="">
            <h2 className="mb-2 text-lg font-medium">Upload documents</h2>
            <p className="text-[#667085] text-sm font-normal ">
              Upload and attach relevant documents
            </p>
          </div>
          <DocumentUpload onClose={closeModal} />
        </div>
      </Modal>
      <DocumentTable rowCount={rowCount} docs={docs} />
    </div>
  );
}

export default Doc;
