import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import TitleSection from "../../../utilities/headers/titleSection";
import CustomInput from "../../../utilities/forms/customInput";
import { Button } from "../../../utilities/button";
import { ExpenseValue } from "../../../utilities/types/types";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import { toast } from "react-toastify";
import { createNewExpenses } from "../../../redux/slices/expenses/createExpense";
import { useNavigate } from "react-router-dom";
// import loader from '../../../asset/images/loader 2.gif';
import {
  getCategory,
  setCategory,
} from "../../../redux/slices/expenses/customCategories";
import CustomSelect from "../../../utilities/components/select";
import ImageUploadCom from "../../../utilities/components/imageUpload";

const validationSchema = Yup.object({
  description: Yup.string()
    .min(5, "Description must be long")
    .required("Description is required"),
  amount: Yup.number().required("Amount is required"),

  // tax: Yup.string().required('Tax is required'),
  category: Yup.string().required("Please select an option"),
  expenseDate: Yup.string().required("Expense Date is required"),
});

function ExpenseCreate() {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const initialValues: ExpenseValue = {
    description: "",
    category: "",
    expenseDate: "",
    amount: "",
    paidBy: "",
    tax: "",
    notes: "",
    file: [],
    createdAt: new Date(),
    // status: '',
  };
  useEffect(() => {
    dispatch(getCategory()).then((response) => {
      dispatch(setCategory(response.payload));
    });
  }, [dispatch]);
  const categories = useAppSelector((state) => state.category.category);

  const [selectedImages, setSelectedImages] = useState([]);

  const navigate = useNavigate();
  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleImageUpload = (newSelectedImages: any) => {
    setSelectedImages(newSelectedImages);
  };

  const handleSubmit = async (data: ExpenseValue) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("description", data.description);
    formData.append("category", data.category);
    formData.append("expenseDate", data.expenseDate);
    formData.append("amount", data.amount.toString());
    formData.append("paidBy", data.paidBy);
    formData.append("tax", data.tax);
    formData.append("notes", data.notes);

    selectedImages.forEach((file: File, index: number) => {
      formData.append(`file`, file);
    });
    try {
      const response = await dispatch(createNewExpenses(formData));
      if (response?.payload === "Network Error") {
        toast.error("Unable to create expense, invalid inputs", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else if (response?.payload.statusCode === 400) {
        toast.error("Expense already exists", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else if (response?.payload.multerError === "file too large") {
        toast.error("File too large", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else if (response?.payload.statusCode === 500) {
        toast.error("Server Error", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else if (
        response?.payload?.statusCode === 200 ||
        response?.payload?.statusCode === 201 ||
        response?.payload?.statusCode === 204
      ) {
        navigate("/user/expense");
        toast.success("Expense created successfully", {
          position: "top-center",
          autoClose: 3000,
          style: { backgroundColor: "#00989B", color: "white" },
        });
        setLoading(false);
      } else {
        navigate("/user/expense");
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#D92D20", color: "white" },
        });
        setLoading(false);
      }
    } catch (error: any) {
      // Handle error
    }
  };

  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/40">
          {/* <img src={loader} alt='' width={800} height={800} /> */}
        </div>
      )}
      <div>
        <TitleSection
          title="New Expense"
          subtitle="Fill out required information and save."
        />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="flex gap-10 px-4">
              <CustomInput
                id="description"
                label="Description"
                type="text"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.errors.description}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[300px] lg:w-[400px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Enter Description"
              />

              <CustomSelect
                label="Category"
                name="category"
                options={[
                  ...categories.map((category) => ({
                    value: category._id,
                    label: category.categoryName,
                  })),
                ]}
                wrapperClassName="pt-[2px]"
                isMulti={false}
                className="focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
              />
            </div>
            <div className="flex gap-10 px-4">
              <CustomInput
                id="expenseDate"
                label="Expense Date"
                type="date"
                name="expenseDate"
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[600px]"
                wrapperClassName=""
                placeholder="Select Date of birth"
                value={formik.values.expenseDate}
                onChange={formik.handleChange}
                error={formik.errors.expenseDate}
              />

              <CustomInput
                id="amount"
                label="Amount"
                type="number"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.errors.amount}
                className=" border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[300px] lg:w-[400px] xl:w-[600px]"
                wrapperClassName=""
                placeholder=""
              />
            </div>
            <div className="flex gap-10 px-4 mb-4">
              {/* <CustomSelect
                name='employee'
                label='Employee'
                options={activeEmployees.map((employee) => ({
                  value: employee.firstName,
                  label: employee.firstName,
                }))}
                isMulti={false}
                className=' focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
              /> */}
              <CustomSelect
                name="paidBy"
                label="Paid By"
                options={[
                  { value: "employee", label: "Employee" },
                  { value: "company", label: "Company" },
                ]}
                isMulti={false}
                className=" focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
              />
              <CustomInput
                id="tax"
                label="Included Taxes(%)"
                type="text"
                name="tax"
                value={formik.values.tax}
                onChange={formik.handleChange}
                error={formik.errors.tax}
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[300px] lg:w-[400px] xl:w-[600px]"
                wrapperClassName=""
                placeholder="Enter tax"
              />
            </div>
            <div className="flex gap-10 ">
              <ImageUploadCom
                selectedImages={selectedImages}
                onImageUpload={(files) => handleImageUpload(files)}
              />
            </div>
            <div className="px-4 mt-6">
              <CustomInput
                id="notes"
                label="Notes"
                type="textarea"
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                error={formik.errors.notes}
                className="w-[98%] p-2 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 h-44"
                wrapperClassName=""
                placeholder=""
              />
            </div>
            {/* <PhoneInputField
              name='phone'
              label='Phone Number:'
              // defaultCountry='US'
            /> */}

            <div className="flex justify-end pr-7">
              <Button
                type="button"
                className="border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="border text-white border-transparent bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left"
              >
                Create Expense
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ExpenseCreate;
