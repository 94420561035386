import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  ExpenseCategories } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

interface CategoryState {
category: ExpenseCategories[];
}

export const getCategory = createAsyncThunk('category', async () => {
  try {
    const response = await Expense_API.category();
    return response.data.data;
  } catch (error) {
    throw error;
  }
});


const categorySlice = createSlice({
  name: 'category',
  initialState: {
    category: [],
  } as CategoryState,
  reducers: {
    setCategory: (state, { payload }) => {
      state.category = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allExpense.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setCategory } = categorySlice.actions;

export default categorySlice.reducer;
