interface TabsProps {
  tabs: string[];
  handleTabClick: (tabNumber: number) => void;
  activeTab: number;
  activeClassName?: string;
  inactiveClassName?: string;
}

function Tabs({
  tabs,
  activeClassName,
  inactiveClassName,
  handleTabClick,
  activeTab,
}: TabsProps) {
  return (
    <div>
      <div className='pl-8 xl:pl-0'>
        <div className=''>
          {tabs.map((tab, index) => (
            <button
              key={index + 1}
              className={
                activeTab === index + 1
                  ? `text-greenDark border-greenDark pb-4 pr-5  ${activeClassName}`
                  : `text-gray-600 pb-4 pr-5  ${inactiveClassName}`
              }
              onClick={() => handleTabClick(index + 1)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tabs;
