import { ReactSVG } from "react-svg";
import phoneIcon from "../../asset/phone.svg";
import mailIcon from "../../asset/mail.svg";
import contractIcon from "../../asset/cryptocurrency.svg";
import briefcaseIcon from "../../asset/briefcase.svg";
import profileIcon from "../../asset/profile.svg";
import ProfileImage from "./profiIemage";
import { ProfileData } from "../../utilities/types/types";

interface ProfileProps {
  showIcons: boolean;
  user: ProfileData;
}

function Profile({ showIcons, user }: ProfileProps): JSX.Element {
  const calculateEmploymentDuration = (): { years: number; months: number } => {
    if (!user?.dateEmployed) {
      return { years: 0, months: 0 };
    }

    const startDate = new Date(user?.dateEmployed);
    const currentDate = new Date();
    let yearsDiff = currentDate.getFullYear() - startDate.getFullYear();
    let monthsDiff = currentDate.getMonth() - startDate.getMonth();

    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff += 12;
    }

    return { years: yearsDiff, months: monthsDiff };
  };

  const { years, months } = calculateEmploymentDuration();

  const formattedDate = user?.dateEmployed
    ? new Date(user.dateEmployed).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : "";

  return (
    <div className={`border rounded-lg h-fit ${showIcons ? "p-14" : "p-6 "}`}>
      <div className={showIcons ? "block" : "flex gap-6 pb-5"}>
        <div>
          <ProfileImage showIcons={showIcons} user={user} />
        </div>

        <div className={showIcons ? "border-b border-[#EAECF0] py-6" : ""}>
          <h2 className={`text-2xl ${showIcons ? "" : "text-[#344054]"}`}>
            <span className="pr-2">{user?.firstName}</span>
            <span>{user?.lastName}</span>
          </h2>
          <p
            className={` ${
              showIcons
                ? "truncate text-sm"
                : "text-[#667085] font-medium  text-base pt-1"
            }`}
          >
            {user?.jobTitle
              ? `${(user?.jobTitle as string).charAt(0).toUpperCase()}${(
                  user?.jobTitle as string
                ).slice(1)}`
              : ""}
            , {user?.department}
          </p>
          {!showIcons && (
            <p className=" text-[#667085] pt-2">
              Employed for {years}years,
              {months}months
            </p>
          )}
        </div>
      </div>
      {showIcons && (
        <div className="block border-b text-[#667085] border-[#EAECF0] pt-6 pb-5">
          <div className="flex gap-1 pb-2">
            <ReactSVG src={phoneIcon} className="pt-1" />
            <p>{user?.phone}</p>
          </div>
          <div className="flex gap-1">
            <ReactSVG src={mailIcon} className="pt-1" />
            <p>{user?.email}</p>
          </div>
        </div>
      )}
      <div
        className={
          showIcons
            ? "border-b border-[#EAECF0] py-4 text-[#667085]"
            : "text-[#667085]"
        }
      >
        <div className={showIcons ? "flex gap-1 pb-2" : "flex gap-20 pb-1"}>
          {showIcons ? (
            <ReactSVG src={contractIcon} className="pt-1" />
          ) : (
            <p>Employment Type</p>
          )}
          <p className={showIcons ? "" : "font-medium"}>
            {user?.employmentType}
          </p>
        </div>
        <div className={showIcons ? "flex gap-1 pb-2" : "flex gap-20 py-1"}>
          {showIcons ? (
            <ReactSVG src={briefcaseIcon} className="pt-1" />
          ) : (
            <p>Departments</p>
          )}
          <p className={showIcons ? "" : "px-8 font-medium"}>
            {user?.department}
          </p>
        </div>
        {/* {showIcons && (
          <div className={showIcons ? 'flex gap-1 pb-2' : 'flex gap-20 py-1'}>
            <ReactSVG src={globeIcon} className='pt-1' />
            <p>{user?.employee?.country}</p>
          </div>
        )} */}
        {/* <div className={showIcons ? 'flex gap-1 pb-2' : 'flex gap-20 py-1'}>
          {showIcons ? (
            <ReactSVG src={markerIcon} className='pt-1' />
          ) : (
            <p>Location</p>
          )}
          <p className={showIcons ? '' : 'px-16'}>{user?.employee?.city}</p>
        </div> */}
      </div>
      {showIcons && (
        <div className="border-b border-[#EAECF0] py-4">
          <p className="pb-2 text-xs text-greenDark">Date of Employment</p>
          <p> {formattedDate}</p>
        </div>
      )}
      <div className={showIcons ? "py-4" : "flex gap-20 py-1"}>
        <p
          className={
            showIcons ? "text-xs text-greenDark pb-2" : " text-[#667085]"
          }
        >
          Line Manager
        </p>
        <div className={showIcons ? "flex gap-4 pt-3" : ""}>
          {showIcons && <ReactSVG src={profileIcon} />}
          <div>
            <p className={showIcons ? "" : "px-7  font-medium text-[#667085]"}>
              {user?.employeeSupervisor}
            </p>
            {showIcons && <p> {user?.employeeSupervisorjobTitle}</p>}
          </div>
        </div>
      </div>
      {!showIcons && (
        <div className="flex gap-20 py-1 text-greenDark">
          <div className="flex items-center gap-1">
            <ReactSVG src={mailIcon} className="pt-1" />
            <p className={showIcons ? "text-sm" : " truncate w-20"}>
              {user?.email}
            </p>
          </div>
          <div className="flex items-center gap-1 px-7">
            <ReactSVG src={phoneIcon} className="pt-1" />
            <p className="">{user?.phone}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
//TODO: profile image upload
