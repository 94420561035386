import React from "react";
import IndividualCelebrations from "./individualCelebration";
import trophy from "../../../asset/trophy.svg";
import arrow from "../../../asset/chevron-right.svg";
import UserCardsHeader from "../../../utilities/headers/userCardsHeader";
import { workAniversary } from "../../../utilities/types/types";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

interface WorkProps {
  userData: workAniversary[];
}

function WorkAniversary({ userData }: WorkProps) {
  const first3Users = userData.slice(0, 3);

  return (
    <div className="w-[50%] p-6 my-3 border border-solid rounded-lg">
      <UserCardsHeader icon={trophy} title="Work Anniversary" />
      <div className="h-64 mt-4 overflow-y-scroll">
        <div className="space-y-4">
          {first3Users.map((user, index) => (
            <IndividualCelebrations key={index} userData={user} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default WorkAniversary;
