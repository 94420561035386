import React from 'react';
import CustomInput from '../../../utilities/forms/customInput';
import { FormikProps } from 'formik';
import { BioDataValues, ProfileData } from '../../../utilities/types/types';
import DisabledInput from '../../../utilities/components/disabledInput';
interface BasicInformationProps {
  formik: FormikProps<BioDataValues>;
  user: ProfileData | null;
}

const BasicInformation: React.FC<BasicInformationProps> = ({
  formik,
  user,
}) => {
  return (
    <div>
      <div className='flex gap-6 py-2'>
        <DisabledInput
          value={user?.firstName}
          label='First Name'
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]'
        />
        <CustomInput
          id='middleName'
          label='Middle Name'
          type='text'
          name='middleName'
          value={formik.values.middleName}
          onChange={formik.handleChange}
          error={formik.errors.middleName}
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]'
          wrapperClassName=''
          placeholder='Enter your Middle Name'
        />
      </div>
      <div className='flex gap-6 py-2'>
        <DisabledInput
          value={user?.lastName}
          label='Last Name'
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]'
        />
        <DisabledInput
          value={user?.staffId}
          label='Staff Id'
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]'
        />
      </div>
      <div className='flex gap-6 py-2'>
        <DisabledInput
          value={user?.email}
          label='Email'
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]'
        />
        <CustomInput
          id='phone'
          label='Phone Number'
          type='tel'
          name='phone'
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.errors.phone}
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[400px] lg:w-[500px] xl:w-[600px]'
          wrapperClassName=''
          placeholder='Enter Phone Number'
        />
      </div>
      <div className='flex gap-6 py-2'>
        <CustomInput
          id='dOB'
          label='Date of Birth'
          type='date'
          name='dOB'
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[400px] lg:w-[500px] xl:w-[600px]'
          wrapperClassName=''
          placeholder='Select Date of birth'
          value={formik.values.dOB}
          onChange={formik.handleChange}
          error={formik.errors.dOB}
        />
        <CustomInput
          id='gender'
          label='Gender'
          type='select'
          name='gender'
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]'
          wrapperClassName=''
          placeholder='Select gender'
          value={formik.values.gender}
          onChange={formik.handleChange}
          error={formik.errors.gender}
          options={['Male', 'Female']}
        />
      </div>
      <div className=''>
        <DisabledInput
          value={user?.employmentType}
          label='Contract Type'
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]'
        />
      </div>
    </div>
  );
};

export default BasicInformation;
