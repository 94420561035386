import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Auth_API from '../../../api/authService/authservice';
import { EmergencyValues } from '../../../utilities/types/types';

interface emergencyContactState {
  contact: EmergencyValues;
}

// Create an async thunk to fetch employee data
export const getEmergencyContact = createAsyncThunk(
  'emergencyContact',
  async () => {
    try {
      const response = await Auth_API.emergency();
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const emergencyContactSlice = createSlice({
  name: 'emergencyContact',
  initialState: {
    contact: {},
  } as emergencyContactState,
  reducers: {
    setEmergencyContact: (state, { payload }) => {
      state.contact = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(ActiveEmployee.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setEmergencyContact } = emergencyContactSlice.actions;

export default emergencyContactSlice.reducer;
