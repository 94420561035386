import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import "react-circular-progressbar/dist/styles.css";
import arrow from "../../asset/chevron-right.svg";
import CircularProgressBar from "./circularBar/circularBar";
import { EmployeeExtra, ProfileData } from "../../utilities/types/types";
import DP from "../profile/displayPicture";

interface ProfileImageProps {
  showIcons: boolean;
  user: ProfileData;
}

function ProfileImage({ showIcons, user }: ProfileImageProps) {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const calculateCompletionPercentage = (user: ProfileData): number => {
      if (!user || !user.employee) {
        return 0;
      }

      const mainKeys = Object.keys(user).filter((key) => key !== "employee");

      const employeeKeys = Object.keys(user.employee);

      let totalFields = mainKeys.length + employeeKeys.length;

      let filledFields = 0;

      mainKeys.forEach((key) => {
        if (
          user[key as keyof ProfileData] !== "" &&
          user[key as keyof ProfileData] !== "null" &&
          user[key as keyof ProfileData] !== "N/A"
        ) {
          filledFields++;
        }
      });

      employeeKeys.forEach((key) => {
        if (user.employee[key as keyof EmployeeExtra] !== "") {
          filledFields++;
        }
      });

      return Math.ceil((filledFields / totalFields) * 100);
    };
    setPercentage(calculateCompletionPercentage(user));
  }, [user]);
  return (
    <div>
      <div className={showIcons ? "px-4" : ""}>
        {showIcons && percentage !== null && percentage < 100 ? (
          <CircularProgressBar percentage={percentage} text={percentage} />
        ) : showIcons && percentage === 100 ? (
          <div>
            <DP showIcons={showIcons} user={user} />
          </div>
        ) : (
          <div>
            <DP showIcons={showIcons} user={user} />
          </div>
        )}
      </div>
      <div
        className={` text-sm font-medium text-greenDark ${
          showIcons ? "px-2" : "px-16"
        }`}
      >
        {showIcons ? (
          <Link to="/user/profile" className="flex gap-2">
            <p>
              {percentage !== null && percentage < 100
                ? "Update Profile"
                : "View Profile"}
            </p>
            <div className="bg-[#E0F6F6] border border-transparent rounded-full">
              <ReactSVG src={arrow} />
            </div>
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default ProfileImage;
