// uploadSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store'; // Make sure to adjust the path based on your project structure
import uploadAxiosService from '../../../api/uploadService'

// Define the state type
interface UploadState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Define the initial state
const initialState: UploadState = {
  status: 'idle',
  error: null,
};

// Define the async thunk for uploading a document
export const uploadDocument = createAsyncThunk(
  'upload/uploadDocument',
  async ({file, documentType}: {file:File, documentType:string}, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('documentType', documentType);

        const response = await uploadAxiosService.postDocument(formData);
    return response.data.data;
    } catch (error) {
      // Handle errors here
      // return thunkAPI.rejectWithValue(error.response.data);
      throw error;
    }
  }
);

// Create the upload slice
const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadDocument.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(uploadDocument.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

// Selectors
export const selectUploadStatus = (state: RootState) => state.upload.status;
export const selectUploadError = (state: RootState) => state.upload.error;

// Export the reducer
export default uploadSlice.reducer;









// // src/features/fileSlice.ts

// import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
// import { FileValues } from '../../../utilities/types/types';
// import uploadAxiosService from '../../../api/uploadService'
// import { RootState } from '../../store';


// // Create an async thunk for uploading files
// export const uploadFiles = createAsyncThunk(
//   'files/upload',
//   async (file: FileValues, thunkAPI) => {
//     try {
//     const response = await uploadAxiosService.postDocument(file);
//     return response.data;
//   } catch (error) {
//     throw error; // Throw the error to be handled by the rejected action
//   }
//   }
// );

// // Create a file slice
// const fileSlice = createSlice({
//   name: 'file',
//   initialState: {
//     formData: {
//       file: '',
//     },
//     error: null as string | null,
//   },
//   reducers: {
//     // Reducer to set form data
//     setFormData: (state, action: PayloadAction<{ file: string }>) => {
//       state.formData = action.payload;
//     },
//     // Reducer to set errors
//     setErrors: (state, action: PayloadAction<string>) => {
//       state.error = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     // Handle the uploadFiles async action
//     builder.addCase(uploadFiles.fulfilled, (state, action) => {
//       state.formData.file = action.payload.data;
//     });
//     builder.addCase(uploadFiles.rejected, (state, action) => {
//       // Handle file upload failure here if needed
//       state.error = action.error.message || 'Failed to upload document';
//     });
//   },
// });

// // Export actions
// export const { setFormData, setErrors } = fileSlice.actions;

// // Export the file slice reducer
// export default fileSlice.reducer;

// // Export a selector function to access the file state from the store
// export const selectFile = (state: RootState) => state.file;
