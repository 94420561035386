import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Auth_API from '../../../api/authService/authservice';

interface logoState {
  logo: string;
}

// Create an async thunk to fetch employee data
export const getLogo = createAsyncThunk(
  'logo',
  async () => {
    try {
      const response = await Auth_API.logo();
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const logoSlice = createSlice({
  name: 'logo',
  initialState: {
    logo: {},
  } as logoState,
  reducers: {
    setLogo: (state, { payload }) => {
      state.logo = payload;
    },
  },

});
export const { setLogo } = logoSlice.actions;

export default logoSlice.reducer;
