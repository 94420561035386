import UserDashboard from "../user";
import ExpenseMgtIndex from "../user/expenseManagement/expenseMgtIndex";
import NewExpense from "../user/expenseManagement/forms/createNewExpense";
import EditExpense from "../user/expenseManagement/forms/editExpense";
import ViewExpense from "../user/expenseManagement/forms/viewExpense";
import NewLeave from "../user/leaveManagement/createLeave";
import LeaveSummary from "../user/leaveManagement/leaveSummary";
import ViewLeaveForm from "../user/leaveManagement/viewLeave";
import Payslip from "../user/payroll/payslip";
import ProfileEditIndex from "../user/profile";
import UserSettingsIndex from "../user/settings";
import NotSubscribed from "../utilities/components/notSubcribed";

export const userRoutes = [
  {
    path: "dashboard",
    component: <UserDashboard />,
  },
  {
    path: "profile",
    component: <ProfileEditIndex />,
  },
  {
    path: "expense",
    component: <ExpenseMgtIndex />,
  },
  {
    path: "payslip",
    component: <Payslip />,
  },
  {
    path: "unsubscribe",
    component: <NotSubscribed />,
  },
  {
    path: "settings",
    component: <UserSettingsIndex />,
  },
  {
    path: "new-expense",
    component: <NewExpense />,
  },
  {
    path: "view-expense/:expenseId",
    component: <ViewExpense />,
  },
  {
    path: "edit-expense/:expenseId",
    component: <EditExpense />,
  },
  {
    path: "leave-summary",
    component: <LeaveSummary />,
  },
  {
    path: "new-leave",
    component: <NewLeave />,
  },
  {
    path: "view-leave/:leaveId",
    component: <ViewLeaveForm />,
  },
];
