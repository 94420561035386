import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Auth_API from '../../../api/authService/authservice';
import { UserData } from '../../../utilities/types/types';

interface newMembersState {
  newMembers: UserData[];
}

// Create an async thunk to fetch employee data
export const getNewMembers = createAsyncThunk('newMembers', async () => {
  try {
    const response = await Auth_API.newOnboard();
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const newMembersSlice = createSlice({
  name: 'newMembers',
  initialState: {
    newMembers: [],
  } as newMembersState,
  reducers: {
    setNewMembers: (state, { payload }) => {
      state.newMembers = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(ActiveEmployee.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setNewMembers } = newMembersSlice.actions;

export default newMembersSlice.reducer;
