import React from 'react';
import CustomInput from '../../../utilities/forms/customInput';

interface SocialMediaProps {
  formik: {
    values: {
      linkedIn: string;
      facebook: string;
      twitter: string;
    };
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errors: {
      linkedIn?: string;
      facebook?: string;
      twitter?: string;
    };
  };
}

const SocialMedia: React.FC<SocialMediaProps> = ({ formik }) => {
  return (
    <div className=''>
      <p className='text-lg font-medium pb-7'>Social Media</p>
      <div className='flex gap-6'>
        <CustomInput
          id='linkedIn'
          label='LinkedIn'
          type='text'
          name='linkedIn'
          value={formik.values.linkedIn}
          onChange={formik.handleChange}
          error={formik.errors.linkedIn}
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]'
          wrapperClassName=''
          placeholder='Enter LinkedIn'
        />
        <CustomInput
          id='facebook'
          label='Facebook'
          type='text'
          name='facebook'
          value={formik.values.facebook}
          onChange={formik.handleChange}
          error={formik.errors.facebook}
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]'
          wrapperClassName=''
          placeholder='Enter Facebook'
        />
      </div>
      <div className='flex gap-6'>
        <CustomInput
          id='twitter'
          label='Twitter'
          type='text'
          name='twitter'
          value={formik.values.twitter}
          onChange={formik.handleChange}
          error={formik.errors.twitter}
          className='border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]'
          wrapperClassName=''
          placeholder='Enter Twitter'
        />
      </div>
    </div>
  );
};

export default SocialMedia;
