import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Document_API from '../../api/uploadService';

// Define the type for your slice state
interface DocumentState {
  documents: any[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Create an async thunk to delete a document
export const deleteDocument = createAsyncThunk(
  'document/deleteDocument',
  async (documentId: string, { rejectWithValue }) => {
    try {
      const response = await Document_API.deleteDocument(documentId);
      return { documentId, ...response.data };
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Create a slice
const documentSlice = createSlice({
  name: 'document',
  initialState: {
    documents: [],
    status: 'idle',
    error: null,
  } as DocumentState,
  reducers: {
    setDocuments: (state, { payload }) => {
      state.documents = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteDocument.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documents = state.documents.filter(
          (doc) => doc.id !== action.payload.documentId
        );
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { setDocuments } = documentSlice.actions;

export default documentSlice.reducer;
