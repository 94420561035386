import UserCardsHeader from "../../utilities/headers/userCardsHeader";
import luggage from "../../asset/luggage.svg";
import { EmptyState } from "../../utilities/components/emptyState";

function Leave() {
  return (
    <div className="w-[35%] p-6 my-3 border border-solid rounded-lg">
      <UserCardsHeader icon={luggage} title="On Leave" />
      <div className="h-64 mt-4 overflow-y-scroll">
        <div className="space-y-4">
          {/* {userData.map((user, index) => (
            <div key={index} className='leave-card'>
              <div className='leave-avatar'>
                <ReactSVG src={user.profile} />
              </div>
              <div className='leave-details'>
                <p className='leave-name'>
                  {user.firstName} {user.lastName}
                </p>

                <div className='leave-dates'>
                  <p>
                    <span>Time-off from </span>
                    <span>{user.leaveDate}</span>
                  </p>
                </div>
              </div>
            </div>
          ))} */}
          <EmptyState
            status="info"
            title=""
            subtitle=""
            message="Coming Soon"
            link=""
            actionLabel=""
            icon=""
          />
        </div>
      </div>
    </div>
  );
}

export default Leave;
