import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import { getAllLeave, setAllLeaves } from "../../redux/slices/leave/allLeave";
import AllLeave from "./table";
import TitleSection from "../../utilities/headers/titleSection";
import add from "../../asset/add.svg";
import Cards from "../../utilities/components/cards";
import { ContentItem } from "../../utilities/types/types";
import { getAnalytics, setAnalytics } from "../../redux/slices/leave/analytics";

function LeaveSummary() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllLeave()).then((response: any) => {
      dispatch(setAllLeaves(response.payload));
    });
    dispatch(getAnalytics()).then((response: any) => {
      dispatch(setAnalytics(response.payload));
    });
  }, [dispatch]);

  const leave = useAppSelector((state) => state.leave.allLeave) || [];
  const analytics = useAppSelector((state) => state.analytics.analytics) || [];
  const allRowCount = leave.length;
  const contents: ContentItem[] = [
    {
      heading: "Total Leave",
      figures: analytics?.totalLeave,
      className: "bg-blueLight px-7 border border-transparent rounded-lg w-56",
      id: 1,
    },
    {
      heading: "Available Leave",
      figures: analytics?.availableLeave,
      className:
        "bg-yellowLight px-7 border border-transparent rounded-lg w-56",
      id: 2,
    },
    {
      heading: "Approved Leave",
      figures: analytics?.approvedLeave,
      className: "bg-[#D1FADF] px-7 border border-transparent rounded-lg w-56",
      id: 3,
    },
    {
      heading: "Unpaid Leave",
      figures: analytics?.unpaidLeave,
      className: "bg-[#FCE7F6] px-7 border border-transparent rounded-lg w-56",
      id: 3,
    },
  ];
  return (
    <div>
      <TitleSection
        title="Leave Summary"
        greenButtonText="Apply for Leave"
        greenBtnLink="/user/new-leave"
        greenButtonIcon={add}
      />
      <Cards contents={contents} />
      <AllLeave leave={leave} rowCount={allRowCount} />
    </div>
  );
}

export default LeaveSummary;
