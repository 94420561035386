import React, { useMemo } from 'react';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { Expense } from '../../../utilities/types/types';
import { ReactSVG } from 'react-svg';
import { ButtonIconLink } from '../../../utilities/button';
import filter from '../../../asset/Filters.svg';
import { EmptyState } from '../../../utilities/components/emptyState';
import Pagination from '../../../utilities/table/pagination';
import Table from '../../../utilities/table/table';
import GlobalFilter from '../../../utilities/table/globalFilter';
import RowRange from '../../../utilities/table/rowsCount';

type expenseTableProps = {
  expense: Expense[];
  rowCount: number;
};

function ReviewExpense({ expense, rowCount }: expenseTableProps) {
  const data = useMemo(() => {
    return expense.map((expense) => ({
      employee: expense.employee,
      description: expense.description,
      expenseDate: expense.expenseDate,
      paidBy: expense.paidBy,
      amount: expense.amount,
      // attachment: expense.attachment,
      status: expense.status,
      file: expense.file,
      _id: expense._id,
    }));
  }, [expense]);
  const columns: Column<Expense>[] = useMemo(
    () => [
      // {
      //   id: 'selection',
      //   Header: ({
      //     getToggleAllRowsSelectedProps,
      //   }: {
      //     getToggleAllRowsSelectedProps: any;
      //   }) => (
      //     <div>
      //       <input type='checkbox' {...getToggleAllRowsSelectedProps()} />
      //     </div>
      //   ),
      //   Cell: ({ row }: { row: any }) => (
      //     <div>
      //       <input type='checkbox' {...row.getToggleRowSelectedProps()} />
      //     </div>
      //   ),
      // },
      // {
      //   Header: 'Employee ',
      //   accessor: (row: any) => `${row.employee} `,
      //   Cell: ({ row }: { row: any }) => {
      //     const fullName = `${row.original.employee} `;
      //     const capitalizedFullName = fullName
      //       .split(' ')
      //       .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      //       .join(' ');
      //     const initials = `${row.original.employee.charAt(0).toUpperCase()}`;
      //     return (
      //       <div className='flex items-center space-r-2 '>
      //         <div className='flex items-center justify-center w-8 h-8 text-white rounded-full flex-2 bg-greenDarker'>
      //           {initials}
      //         </div>
      //         <p className='flex-1 p-2 text-left'>{capitalizedFullName}</p>
      //       </div>
      //     );
      //   },
      // },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Expense Date',
        accessor: (row) => new Date(row.expenseDate).toLocaleDateString(),
      },
      {
        Header: 'Paid By',
        accessor: 'paidBy',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Attachment',
        accessor: (row: any) => (row.file ? row.file.length : 0),
        Cell: ({ cell: { value } }: { cell: { value: number } }) => (
          <div>{value}</div>
        ),
        id: 'attachment',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <div>
            <p
              style={{
                backgroundColor:
                  value === 'Review'
                    ? 'green'
                    : value === 'approved'
                    ? '#ECFDF3'
                    : value === 'request'
                    ? '#FFF6ED'
                    : '#EFF8FF',
                borderColor: 'transparent',
                textAlign: 'center',
                padding: '2px 8px',
                borderRadius: '16px',
                color:
                  value === 'request'
                    ? '#C4320A'
                    : value === 'review'
                    ? 'green'
                    : value === 'approved'
                    ? '#027A48'
                    : '#175CD3',
              }}
            >
              {value}
            </p>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<Expense>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 }, // Set initial page index and page size
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className='mx-auto border-2 border-solid rounded-lg border-[#EAECF0]'>
      <div className=''>
        <div className='flex justify-start gap-2 px-6 py-5'>
          <p className='text-lg text-grayText '>Review Expenses</p>
          <div className='border rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]'>
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>
      <div className='flex justify-between items-center lg:gap-[50%] xl:gap-[50%] md:[25%] my-3 mx-4 '>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <ButtonIconLink
          className='pt-[10px] pb-[7px] px-4 item-center'
          src={filter}
          link='#'
          text='Filters'
        />
      </div>

      {data.length === 0 ? (
        <EmptyState
          title='No expense data found'
          subtitle=''
          message='Kindly “Create Expenses” to populate table data.'
          link=''
          actionLabel=''
          icon=''
          status='error'
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={data}
            viewBasePath='/user/view-expense'
            tooltip
            tooltipButtons={{
              view: true,
            }}
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default ReviewExpense;
