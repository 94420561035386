import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Leave_API from "../../../api/leaveService";

interface Analytic {
  totalLeave: number;
  approvedLeave: number;
  availableLeave: number;
  unpaidLeave: number;
}

// Define the type for your slice state
interface AnalyticState {
  analytics: Analytic;
}

// Create an async thunk to fetch employee data
export const getAnalytics = createAsyncThunk("analytics", async () => {
  try {
    const response = await Leave_API.analytics();
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    analytics: {},
  } as AnalyticState,
  reducers: {
    setAnalytics: (state, { payload }) => {
      state.analytics = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(analytics.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setAnalytics } = analyticsSlice.actions;

export default analyticsSlice.reducer;

// Selectors
// export const selectEmployees = (state:EmployeeState) => state.employees.employees;
// export const selectEmployeesStatus = (state:EmployeeState) =>
//   state.employees.status;
// export const selectEmployeesError = (state:EmployeeState) => state.employees.error;
