import { useEffect } from "react";
import TitleSection from "../../utilities/headers/titleSection";
import PayslipTable from "./payslipTable";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import { getPayslip, setPayslip } from "../../redux/slices/payslipSlice";
import { getPayslipData, setPayslipData } from "../../redux/slices/paySlipData";

function Payslip() {
  const dispatch = useAppDispatch();
  const payslip = useAppSelector((state) => state.payslip.payslip);

  useEffect(() => {
    dispatch(getPayslip()).then((response: any) => {
      dispatch(setPayslip(response.payload));
    });
    dispatch(getPayslipData()).then((response: any) => {
      dispatch(setPayslipData(response.payload));
    });
  }, [dispatch]);

  const payslipData = useAppSelector((state) => state.payslipData.payslip);

  const rowCount = payslip.length;

  return (
    <div>
      <TitleSection title="Payslip" />
      <div className="">
        <div className="flex gap-[72px] px-4 py-8 border rounded-lg  mb-8">
          <div className="p-4">
            <p className="text-[28px] font-medium"> {payslipData.grossPay}</p>
            <p className="text-[#667085]">Gross Pay</p>
          </div>
          <div className="py-4">
            <p className="text-[28px] font-medium">{payslipData.date}</p>
            <p className="text-[#667085] ">
              Payment due in {payslipData.paymentDue}
            </p>
          </div>
        </div>
        <PayslipTable rowCount={rowCount} paySlip={payslip} />
      </div>
    </div>
  );
}

export default Payslip;
