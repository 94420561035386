import '../../../styles/auth.css';
import AuthLayout from '../authLayout';
import LoginForm from '../login';

function Login() {
  return (
    <>
      {/* <Head>
        <meta name="description" content="" />
        <title>Log In</title>
      </Head> */}
      <AuthLayout>
        <div className='formSection'>
          <div className='formText'>
            <h2 className='formHeader'>HR Log in Portal </h2>
          </div>

          <div>
            <LoginForm />
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default Login;
