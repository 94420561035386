import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useState } from 'react';
import { classNames } from './table/data/Utils';

type props1 = {
  className: string;
  link: string;
  text: string;
};
type ClickProps = {
  className?: string;
  src: string;
  text: string;
  onClick: () => void; // Add the onClick prop here
};
type props2 = {
  text: string;
  number: number;
};
type props3 = {
  className: string;
  link: string;
  text: string;
  src: string;
};
type props4 = {
  className: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
};
type props5 = {
  className: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  disabled: boolean;
};

interface RowProps {
  className: string;
  link: string;
  text: string;
  rowId: number;
}

export function RowBtn({ className, link, text, rowId }: RowProps) {
  const [, setClickedRowId] = useState<number | null>(null);

  const handleClick = () => {
    setClickedRowId(rowId);
  };

  const linkWithUserId = `${link}?userId=${rowId}`;

  return (
    <div
      className={`flex px-[18px] py-[10px] border rounded-lg ${className}`}
      onClick={handleClick}
    >
      <Link to={linkWithUserId} className='pl-1 no-underline'>
        {text}
      </Link>
    </div>
  );
}

// export function RowBtn({ className, link, text, rowId }: rowProps) {
//   const handleClick = () => {
//     // Use the rowId in your button logic
//     console.log(`Button clicked for row with ID: ${rowId}`);
//     // ... (other logic)
//   };
//   return (
//     <div
//       className={`flex px-[18px] py-[10px] border rounded-lg   ${className}`}
//       onClick={handleClick}
//     >
//       <Link to={link} className="pl-1 no-underline">
//         {text}
//       </Link>
//     </div>
//   );
// }
export function Btn({ className, link, text }: props1) {
  return (
    <div
      className={`flex px-[18px] py-[10px] border rounded-lg   ${className}`}
    >
      <Link to={link} className='pl-1 no-underline'>
        {text}
      </Link>
    </div>
  );
}

export function BtnText({ text, number }: props2) {
  return (
    <div className='border border-transparent'>
      <div className='flex p-2 mx-1 border rounded-lg'>
        <p className=''>{text}</p>
        <p className='px-2 ml-2 border border-transparent rounded-full bg-greyBtn'>
          {number}
        </p>
      </div>
    </div>
  );
}

export function ButtonIconLink({ className, src, link, text }: props3) {
  return (
    <div className={`flex px-[18px] py-[10px] border rounded-lg  ${className}`}>
      <div className='p-1'>
        <ReactSVG src={src} />
      </div>
      <Link to={link} className='pl-1 no-underline'>
        {text}
      </Link>
    </div>
  );
}
export function ButtonIconClick({ className, src, onClick, text }: ClickProps) {
  return (
    <div
      className={`flex px-[18px] py-[10px] border rounded-lg  ${className}`}
      onClick={onClick}
    >
      <div className='pr-1'>
        <ReactSVG src={src} />
      </div>
      <div className=''>{text}</div>
    </div>
  );
}
export function PageButton({ children, className, onClick, ...rest }: props5) {
  return (
    <button
      type='button'
      className={classNames(
        'relative inline-flex items-center px-6 py-4 border rounded-lg gap-x-3 border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}
export function Button({
  children,
  className,
  onClick,
  type,
  disabled,
  ...rest
}: props4) {
  return (
    <button
      type={type}
      className={classNames('relative block items-center  ', className)}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}
