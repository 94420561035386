import { ReactSVG } from "react-svg";
import profile from "../asset/profile.svg";
import accordion from "../asset/accordion.svg";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../redux/hook/authHook";
import {
  getEmployee,
  setUserData,
} from "../redux/slices/employes/userDataSlice";
import { logout } from "../redux/slices/auth/authSlice";

function HeaderProfile() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEmployee()).then((response) => {
      dispatch(setUserData(response.payload));
    });
  }, [dispatch]);
  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        navigate("/login");
        toast.success("Logout successful", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
      })
      .catch((error) => {
        console.error("Logout failed:", error);
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      });
  };
  const user = useAppSelector((state) => state.user.userData);
  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div className="flex" onClick={toggleDropdown}>
        <div className="px-3">
          {user?.profileImage ? (
            <img
              src={user?.profileImage}
              alt="Selected"
              className="object-cover w-10 h-10 rounded-full"
            />
          ) : (
            <ReactSVG src={profile} />
          )}
        </div>

        <div>
          <h3 className="line-clamp-1">{user?.firstName}</h3>
          <p>
            {user?.jobTitle
              ? `${(user?.jobTitle as string).charAt(0).toUpperCase()}${(
                  user?.jobTitle as string
                ).slice(1)}`
              : ""}
          </p>
        </div>
        <div className="p-4">
          <ReactSVG src={accordion} />
        </div>
      </div>
      {isOpen && (
        <div className="absolute right-0 z-50 w-48 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Link
              to="/user/profile"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Profile
            </Link>
            <Link
              to="/user/settings"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Settings
            </Link>
            <p
              onClick={handleLogout}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Logout
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeaderProfile;
