
import axiosService from './axios';
import uploadAxiosService from './axiosUpload';

const API_URL = process.env.REACT_APP_API_URL;

export default class Document_API {
  
  static async postDocument(data: FormData): Promise<any> {
    return await uploadAxiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}document/create`,
    });
  }
  static async uploadImage(data: FormData): Promise<any> {
    return await uploadAxiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}/upload`,
    });
  }
  static async getDocument(): Promise<any> {
    return await uploadAxiosService({
      method: 'GET',
      url: `${API_URL}document`,
    });
  }
 

  static async deleteDocument(documentId: string): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      url: `${API_URL}document/delete/${documentId}`,
    });
  }
}
