import { useEffect, useState } from "react";
import TitleSection from "../../utilities/headers/titleSection";
import add from "../../asset/add.svg";
import Tabs from "../../utilities/tabs";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import AllExpense from "./tables/allExpense";
import RequestExpense from "./tables/requestExpense";
import ReviewExpense from "./tables/reviewExpense";
import ReimburseExpenses from "./tables/reimemburseExpense";
import {
  getAllExpenses,
  setAllExpenses,
} from "../../redux/slices/expenses/allExpenses";
import {
  getApprovedExpenses,
  setApprovedExpenses,
} from "../../redux/slices/expenses/approveExpense";
import {
  getReimbursedExpenses,
  setReimbursedExpenses,
} from "../../redux/slices/expenses/reimburseExpense";
import {
  getRequestedExpenses,
  setRequestedExpenses,
} from "../../redux/slices/expenses/requestExpense";
import {
  getEmployee,
  setUserData,
} from "../../redux/slices/employes/userDataSlice";

function ExpenseMgtIndex() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllExpenses()).then((response) => {
      dispatch(setAllExpenses(response.payload));
    });
    dispatch(getApprovedExpenses()).then((response) => {
      dispatch(setApprovedExpenses(response.payload));
    });
    dispatch(getReimbursedExpenses()).then((response) => {
      dispatch(setReimbursedExpenses(response.payload));
    });
    dispatch(getRequestedExpenses()).then((response) => {
      dispatch(setRequestedExpenses(response.payload));
    });
    dispatch(getEmployee()).then((response) => {
      dispatch(setUserData(response.payload));
    });
  }, [dispatch]);

  const user = useAppSelector((state) => state.user);

  const allExpenses = useAppSelector((state) => state.allExpenses.allExpense);

  const approvedExpenses = useAppSelector(
    (state) => state.approvedExpenses.approvedExpense
  );
  const requestedExpenses = useAppSelector(
    (state) => state.requestedExpenses.requestedExpense
  );
  const reimbursedExpenses = useAppSelector(
    (state) => state.reimbursedExpenses.reimbursedExpense
  );
  const allRowCount = allExpenses.length;
  const approvedRowCount = approvedExpenses.length;
  const requestedRowCount = approvedExpenses.length;
  const reimburseRowCount = reimbursedExpenses.length;
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };
  return (
    <div>
      <TitleSection
        title="Expense Summary"
        greenButtonText="New Expense"
        greenBtnLink="/user/new-expense"
        greenButtonIcon={add}
      />
      <Tabs
        tabs={["All Expenses", "Request", "Review", "Reimburse"]}
        handleTabClick={handleTabClick}
        activeTab={activeTab}
        activeClassName="text-greenDark border-b text-center px-4 py-2 mb-8"
        inactiveClassName="px-4 py-2 mb-2"
      />
      <div className="tab-content">
        {activeTab === 1 && (
          <AllExpense expense={allExpenses} rowCount={allRowCount} />
        )}
        {activeTab === 2 && (
          <RequestExpense
            expense={requestedExpenses}
            rowCount={requestedRowCount}
          />
        )}
        {activeTab === 3 && (
          <ReviewExpense
            expense={approvedExpenses}
            rowCount={approvedRowCount}
          />
        )}
        {activeTab === 4 && (
          <ReimburseExpenses
            expense={reimbursedExpenses}
            rowCount={reimburseRowCount}
          />
        )}
        {/* {activeTab === 5 && <Payroll />} */}
      </div>
    </div>
  );
}

export default ExpenseMgtIndex;
