import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAppDispatch } from "../../../redux/hook/authHook";

interface TextEditorProps {
  id: string;
  handleSubmit: (editorHtml: string, id: string) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({ id, handleSubmit }) => {
  const [editorHtml, setEditorHtml] = useState<string>("");
  const dispatch = useAppDispatch();

  const handleChange = (html: string) => {
    setEditorHtml(html);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(editorHtml, id);
  };

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={editorHtml}
        onChange={handleChange}
        style={{ height: "calc(100% - 32px)" }}
      />

      <button
        className="border mt-7 text-white border-transparent bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left"
        onClick={onSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default TextEditor;
