// src/store/imageUploadSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import uploadAxiosService from '../../../api/axiosUpload';
import Document_API from '../../../api/uploadService';

interface ImageUploadState {
  file: File | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string | null;
  imageUrl: string | null;
}

const initialState: ImageUploadState = {
  file: null,
  loading: 'idle',
  error: null,
  imageUrl: null
};

export const uploadImage = createAsyncThunk('imageUpload/uploadImage', async (formData: FormData, thunkAPI) => {
  try {
    
    
    const response = await Document_API.uploadImage(formData);

    return response.data;
  } catch (error) {
    // return thunkAPI.rejectWithValue(error.response?.data || 'Failed to upload image');
  }
});

const imageUploadSlice = createSlice({
  name: 'imageUpload',
  initialState,
  reducers: {
      setImageURL: (state, action) => {
      state.file = null; // Assuming you want to reset the file reference
      state.loading = 'idle';
      state.error = null;
      state.imageUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImage.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.file = action.payload.data;
        state.imageUrl = action.payload.data
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message || 'Failed to upload image';
      });
  },
});

export default imageUploadSlice.reducer;
