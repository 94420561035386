import { useEffect } from "react";
import userPlus from "../../asset/usersPlus.svg";
import UserCardsHeader from "../../utilities/headers/userCardsHeader";
import { EmptyState } from "../../utilities/components/emptyState";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import {
  getNewMembers,
  setNewMembers,
} from "../../redux/slices/employes/newMebers";

function NewMembers() {
  const dispatch = useAppDispatch();

  // Fetch new members on component mount
  useEffect(() => {
    const fetchNewMembers = async () => {
      try {
        const response = await dispatch(getNewMembers());
        if (response.payload) {
          dispatch(setNewMembers(response.payload));
        }
      } catch (error) {}
    };

    fetchNewMembers();
  }, [dispatch]);

  const newMembers = useAppSelector((state) => state.newMembers.newMembers);
  return (
    <div className="w-[35%] p-6 my-3 border border-solid rounded-lg">
      <UserCardsHeader icon={userPlus} title="New Team Members" />
      <div className="h-64 mt-4 overflow-y-scroll">
        <div className="space-y-4">
          {newMembers && newMembers.length > 0 ? (
            newMembers.map((member, index) => (
              <div key={index} className="flex items-center leave-card">
                <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-gray-300 rounded-full">
                    {member.firstName.charAt(0)}
                    {member.lastName.charAt(0)}
                  </div>
                </div>
                <div className="flex flex-col ml-4 leave-details">
                  <p className="leave-name">
                    {member.firstName} {member.lastName}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <EmptyState
              status="info"
              title="No New Team Members"
              subtitle=""
              message="No new team members have joined recently."
              link=""
              actionLabel=""
              icon=""
            />
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default NewMembers;
