import React from 'react';
import { ReactSVG } from 'react-svg';
import search from '../../asset/search.svg';

type GlobalFilterProps = {
  filter: string;
  setFilter: (filterValue: string) => void;
};

const GlobalFilter: React.FC<GlobalFilterProps> = ({ filter, setFilter }) => {
  return (
    <div className='flex gap-2 border border-solid rounded-lg border-[#D0D5DD] my-3 py-1  mx-4 w-[200px] lg:w-[250px] xl:w-[350px]'>
      <ReactSVG src={search} className='pt-2 pb-2 pl-[14px] ' />
      <input
        type='text'
        value={filter || ''}
        onChange={(e) => setFilter(e.target.value)}
        placeholder='Search...'
        className='w-[100%] h-8 border border-transparent hover:border-transparent active:border-transparent rounded-lg '
      />
    </div>
  );
};

export default GlobalFilter;
