import React from "react";
import CustomInput from "../../../utilities/forms/customInput";
import { FormikProps } from "formik";
import { BioDataValues } from "../../../utilities/types/types";

interface AddressProps {
  formik: FormikProps<BioDataValues>;
}

const Address: React.FC<AddressProps> = ({ formik }) => {
  return (
    <div className="">
      <p className="text-lg font-medium pb-7">Address</p>
      <div className="flex gap-6">
        <CustomInput
          id="homeAddress"
          label="Home Address"
          type="text"
          name="homeAddress"
          value={formik.values.homeAddress}
          onChange={formik.handleChange}
          error={formik.errors.homeAddress}
          className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
          wrapperClassName=""
          placeholder="Enter your Home Address"
        />
        <CustomInput
          id="city"
          label="City"
          type="text"
          className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
          wrapperClassName=""
          placeholder="Select city"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.errors.city}
          options={["red", "blue", "green"]}
        />
      </div>
      <div className="flex gap-6">
        <CustomInput
          id="country"
          label="Country"
          type="text"
          name="country"
          className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
          wrapperClassName=""
          placeholder="Select Country"
          value={formik.values.country}
          onChange={formik.handleChange}
          error={formik.errors.country}
          // options={['red', 'blue', 'green']}
        />
        <div className="flex gap-6">
          <CustomInput
            id="state"
            label="State"
            type="text"
            name="state"
            value={formik.values.state}
            onChange={formik.handleChange}
            error={formik.errors.state}
            // options={['red', 'blue', 'green']} // Pass the list of options
            className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[150px] xl:w-[285px]"
            wrapperClassName=""
            placeholder="Select State "
          />
          <CustomInput
            id="zipCode"
            label="ZipCode"
            type="number"
            name="zipCode"
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            error={formik.errors.zipCode}
            className=" border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[150px] xl:w-[285px]"
            wrapperClassName=""
            placeholder=""
          />
        </div>
      </div>
      <div className="flex gap-6">
        <CustomInput
          id="homePhone"
          label="Home Phone"
          type="text"
          name="homePhone"
          value={formik.values.homePhone}
          onChange={formik.handleChange}
          error={formik.errors.homePhone}
          className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
          wrapperClassName=""
          placeholder="Enter Home Phone"
        />
        <CustomInput
          id="mobilePhone"
          label="Mobile Phone"
          type="text"
          name="mobilePhone"
          value={formik.values.mobilePhone}
          onChange={formik.handleChange}
          error={formik.errors.mobilePhone}
          className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
          wrapperClassName=""
          placeholder="Enter Mobile Phone"
        />
      </div>
      <div className="flex gap-6">
        <CustomInput
          id="workPhone"
          label="Work Phone"
          type="text"
          name="workPhone"
          value={formik.values.workPhone}
          onChange={formik.handleChange}
          error={formik.errors.workPhone}
          className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
          wrapperClassName=""
          placeholder="Enter Work Phone"
        />

        <CustomInput
          id="alterativeEmail"
          label="Alterative Email"
          type="text"
          name="alternativeEmail"
          value={formik.values.alternativeEmail}
          onChange={formik.handleChange}
          error={formik.errors.alternativeEmail}
          className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
          wrapperClassName=""
          placeholder="Enter Alterative Email"
        />
      </div>
    </div>
  );
};

export default Address;
