/**
 * TIPS
 * Read up some react tips for improving performance
 * https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
 */

import axios from "axios";
import AuthModule from "./authService/Auth.module";

type payload = {
  url: string;
  method: string;
  data?: any;
  signal?: any;
  params?: any;
  headers?: any;
};
/**
 * Helper for calling axios services
 */
const axiosService = async ({
  url,
  method,
  data,
  signal,
  params,
  headers,
}: payload): Promise<any> => {
  AuthModule.authenticateOrg();


  return await axios({
    method,
    url,
    ...(params && { params }),
    ...(signal && { cancelToken: signal }),
    ...(data && { data }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'x-quiickops-customerId': AuthModule.getOrganization()||"test"  ,
      ...(AuthModule.getToken() && {
        Authorization: `Bearer ${AuthModule.getToken()}`,
      }),
   
    },
    
  
  });
};

export default axiosService;
