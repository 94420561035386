import { useMemo } from "react";
import { useTable } from "react-table";
import { ProfileData } from "../../utilities/types/types";
import EmploymentStatus from "./employmentStatus";

interface JobProps {
  user: ProfileData;
}

function formatDateString(date: Date | string | undefined) {
  if (!date) return ""; // Return an empty string if 'date' is undefined or falsy

  const formattedDate = typeof date === "string" ? new Date(date) : date;
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return formattedDate.toLocaleDateString("en-US", options);
}

function JobInfo({ user }: JobProps) {
  const formattedDateEmployed = formatDateString(user?.dateEmployed);
  // const dispatch = useDispatch();
  const initialValues = {
    dutyVet: false,
    medalVet: false,
    disabledVet: false,
    seperatedVet: false,
  };

  const COLUMNS: any = [
    {
      Header: "Employment Date",
      accessor: "dateEmployed",
    },
    {
      Header: "Department",
      accessor: "department",
    },
    {
      Header: "Location",
      accessor: "country",
    },
    {
      Header: "Job Title",
      accessor: "jobTitle",
    },
    {
      Header: "Line Manager",
      accessor: "employeeSupervisor",
    },
  ];

  const users = [
    {
      dateEmployed: formattedDateEmployed,
      department: user?.department,
      country: user?.employee?.country,
      jobTitle: user?.jobTitle,
      employeeSupervisor: user?.employeeSupervisor,
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => users, []);

  const tableInstance = useTable({
    columns,
    data,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div>
      <div>
        <h2 className="pb-7">Job Information</h2>

        <table {...getTableProps()} className="w-[100%] border-collapse">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((columns) => (
                  <th
                    {...columns.getHeaderProps()}
                    className="p-2 bg-[#F2F4F7] font-normal"
                  >
                    {columns.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="">
                  {row.cells.map((data) => {
                    return (
                      <td {...data.getCellProps()} className="p-2 text-center">
                        {data.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <h2 className="py-7">Employment Status</h2>

        <EmploymentStatus user={user} />
      </div>
      {/* <div className='py-20'>
        <h2 className='text-lg font-medium text-[#344054] mb-6'>
          Veteran Status
        </h2>
        <div className='pl-6'>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              // Handle form submission here
            }}
          >
            {(formikProps) => (
              <Form>
                <CustomInput
                  id='dutyVet'
                  placeholder=''
                  label='Active Duty Wartime or Campaign Badge Veteran'
                  type='checkbox'
                  name='dutyVet'
                  value={formikProps.values.dutyVet}
                  onChange={(e) => {
                    formikProps.setFieldValue('dutyVet', e.target.checked);
                  }}
                  error={formikProps.errors.dutyVet}
                  className='text-sm font-medium'
                  wrapperClassName='my-checkbox-wrapper-class'
                />
                <CustomInput
                  id='medalVet'
                  disabled={false}
                  placeholder=''
                  label='Arrived Forces Services Medal Veteran'
                  type='checkbox'
                  name='medalVet'
                  value={formikProps.values.medalVet}
                  onChange={(e) => {
                    formikProps.setFieldValue('medalVet', e.target.checked);
                  }}
                  error={formikProps.errors.medalVet}
                  className='text-sm font-medium'
                  wrapperClassName='my-checkbox-wrapper-class'
                />
                <CustomInput
                  id='disabledVet'
                  disabled={false}
                  placeholder=''
                  label='Disabled Veteran'
                  type='checkbox'
                  name='disabledVet'
                  value={formikProps.values.disabledVet}
                  onChange={(e) => {
                    formikProps.setFieldValue('disabledVet', e.target.checked);
                  }}
                  error={formikProps.errors.disabledVet}
                  className='text-sm font-medium'
                  wrapperClassName='my-checkbox-wrapper-class'
                />
                <CustomInput
                  id='seperatedVet'
                  disabled={false}
                  placeholder=''
                  label='Recently Separated Veteran'
                  type='checkbox'
                  name='seperatedVet'
                  value={formikProps.values.seperatedVet}
                  onChange={(e) => {
                    formikProps.setFieldValue('seperatedVet', e.target.checked);
                  }}
                  error={formikProps.errors.seperatedVet}
                  className='text-sm font-medium'
                  wrapperClassName='my-checkbox-wrapper-class'
                />

                <button
                  type='submit'
                  className='border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left mt-8'
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div> */}
    </div>
  );
}

export default JobInfo;
