import React, { useEffect } from "react";
import Leave from "./leaveManagement/leave";

import TitleSection from "../utilities/headers/titleSection";
import Anouncement from "./useroverview/anouncement";
import OnboardingTask from "./useroverview/onboardingTask";
import CompanyLinks from "./useroverview/companyLinks";
import NewMembers from "./useroverview/newMember";
import Profile from "./useroverview/profile";
import { useAppDispatch, useAppSelector } from "../redux/hook/authHook";
import {
  getEmployee,
  setUserData,
} from "../redux/slices/employes/userDataSlice";
import { getBirthday, setBirthday } from "../redux/slices/employes/birthday";
import {
  getWorkAniversary,
  setWorkAniversary,
} from "../redux/slices/employes/workAniversary";
import Birthday from "./useroverview/celebrations/birthdays";
import WorkAniversary from "./useroverview/celebrations/workAniversary";
import { ProfileData } from "../utilities/types/types";
import {
  getEmergencyContact,
  setEmergencyContact,
} from "../redux/slices/employes/emergencyContact";
import emergency from "../redux/slices/auth/emergency";

function UserDashboard() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEmployee()).then((response) => {
      dispatch(setUserData(response.payload));
    });
    dispatch(getEmergencyContact()).then((response) => {
      dispatch(setEmergencyContact(response.payload));
    });
    dispatch(getBirthday()).then((response) => {
      dispatch(setBirthday(response.payload));
    });
    dispatch(getWorkAniversary()).then((response) => {
      dispatch(setWorkAniversary(response.payload));
    });
  }, [dispatch]);

  const user = useAppSelector((state) => state.user.userData) as ProfileData;
  const birthday = useAppSelector((state) => state.birthdays.birthday);
  const workAniversary = useAppSelector((state) => state.work.work);
  const emergencyData = useAppSelector(
    (state) => state.emergencyContact.contact
  );

  return (
    <div className="pb-10">
      <div className="py-8">
        <h3 className="text-lg font-medium xl:text-3xl text-grayText">
          Welcome back, {user?.firstName}
        </h3>
        <p className="text-base font-normal text-[#667085]">
          Manage and track employee’s performance.
        </p>
      </div>
      <div className="">
        <div className="flex gap-[2%]">
          <Profile showIcons user={user} />
          <div className="w-[80%]">
            <OnboardingTask user={user} emergency={emergencyData} />
            <Anouncement />
          </div>
        </div>
        <div className="flex gap-6 h-[420px] mt-6 mb-3">
          <Birthday userData={birthday} />
          <WorkAniversary userData={workAniversary} />
        </div>
        <div className="flex gap-6">
          <CompanyLinks /> <Leave /> <NewMembers />
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
//TODO: onboarding task
