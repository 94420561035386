// import { data } from "../utilities/table/data/tableData";
import Tabs from '../../utilities/tabs';
import { useState } from 'react';
// import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";

import TitleSection from '../../utilities/headers/titleSection';
import UserChangePasswordForm from './changePassword';
import FactorAuth from './factorAuth';
import BioData from '../profile/biodata';

function UserSettingsIndex() {
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  return (
    <div>
      <TitleSection
        title='Settings'
        subtitle='Manage your team and preferences here.'
        greenBtnLink=''
        grayBtnLink=''
        greenButtonIcon=''
        grayButtonIcon=''
      />
      <hr className='text-[#EAECF0] pb-6' />
      <div className=''>
        <Tabs
          tabs={['Account Info ', 'Change Password', '2 step Auth']}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          activeClassName=''
          inactiveClassName=''
        />
      </div>
      <hr className='text-[#EAECF0] pt-6' />
      <div>
        {activeTab === 1 && <BioData />}
        {activeTab === 2 && <UserChangePasswordForm />}
        {activeTab === 3 && <FactorAuth />}
      </div>
    </div>
  );
}

export default UserSettingsIndex;
