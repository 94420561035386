import React from 'react';
import { EmptyState } from '../../utilities/components/emptyState';
import off from '../../asset/alarm.svg';

function TimeOff() {
  return (
    <div>
      <EmptyState
        title=''
        subtitle=''
        message='Time Off hasn’t been setup for you yet.'
        link=''
        actionLabel=''
        icon={off}
        status='default'
      />
    </div>
  );
}

export default TimeOff;
