import React, { useEffect, useState } from "react";
import CustomInput from "../../../utilities/forms/customInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage, FormikProps } from "formik";
import { BioDataValues, ProfileData } from "../../../utilities/types/types";
interface EducationProps {
  formik: FormikProps<BioDataValues>;
  user: ProfileData | null;
}

interface EducationEntry {
  college: string;
  degree: string;
  major: string;
  gpa: string;
  startDate: Date | null;
  endDate: Date | null;
}

const Education: React.FC<EducationProps> = ({ formik, user }) => {
  const [educationEntries, setEducationEntries] = useState<EducationEntry[]>([
    {
      college: "",
      degree: "",
      major: "",
      gpa: "",
      startDate: null,
      endDate: null,
    },
  ]);

  useEffect(() => {
    if (user?.employee?.education) {
      const initialEducationEntries = user.employee.education?.map((edu) => ({
        college: edu?.college || "",
        degree: edu?.degree || "",
        major: edu?.major || "",
        gpa: edu?.gpa || "",
        startDate: edu?.startDate ? new Date(edu.startDate) : null,
        endDate: edu?.endDate ? new Date(edu.endDate) : null,
      }));
      setEducationEntries(initialEducationEntries);
    }
  }, [user]);

  const handleAddEducation = () => {
    setEducationEntries([...educationEntries, { ...educationEntries[0] }]);
  };

  const handleRemoveEducation = (index: number) => {
    const updatedEntries = [...educationEntries];
    updatedEntries.splice(index, 1);
    setEducationEntries(updatedEntries);
  };

  const handleChangeEducation = <T extends keyof EducationEntry>(
    index: number,
    field: T,
    value: EducationEntry[T]
  ) => {
    const updatedEntries = [...educationEntries];
    const updatedEntry = { ...updatedEntries[index], [field]: value };
    updatedEntries[index] = updatedEntry;
    setEducationEntries(updatedEntries);

    // Update formik values
    formik.setFieldValue("education", updatedEntries);
  };

  return (
    <div className="">
      <p className="text-lg font-medium pb-7">Education</p>
      {educationEntries.map((entry: EducationEntry, index) => (
        <div key={index}>
          <div className="flex gap-6">
            <CustomInput
              id="college"
              label="College"
              type="text"
              name="college"
              value={entry.college}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeEducation(index, "college", e.target.value)
              }
              error={
                formik.errors.education &&
                (formik.errors.education[index] as unknown as EducationEntry)
                  ?.college
              }
              className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
              wrapperClassName=""
              placeholder="Enter College"
            />
            <CustomInput
              id="degree"
              label="Degree"
              type="text"
              name="degree"
              value={entry.degree}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeEducation(index, "degree", e.target.value)
              }
              error={
                formik.errors.education &&
                (formik.errors.education[index] as unknown as EducationEntry)
                  ?.degree
              }
              className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
              wrapperClassName=""
              placeholder="Enter Degree"
            />
          </div>
          <div className="flex gap-6">
            <CustomInput
              id="major
"
              label="Major/Specialization"
              type="text"
              name="major
"
              value={entry.major}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeEducation(index, "major", e.target.value)
              }
              error={
                formik.errors.education &&
                (formik.errors.education[index] as unknown as EducationEntry)
                  ?.major
              }
              className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] lg:w-[500px] xl:w-[600px]"
              wrapperClassName=""
              placeholder="Enter Major/Specialization"
            />
            <div className="gap-6 xl:flex">
              <CustomInput
                id="gpa
  "
                label="GPA"
                type="text"
                name="gpa
  "
                value={entry.gpa}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeEducation(index, "gpa", e.target.value)
                }
                error={
                  formik.errors.education &&
                  (formik.errors.education[index] as unknown as EducationEntry)
                    ?.gpa
                }
                className="border border-gray-400 bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[150px] xl:w-[285px]"
                wrapperClassName=""
                placeholder="Enter GPA"
              />
              <div className="flex gap-6 order-0">
                <div>
                  <label>Date Range</label>
                  <div className="relative flex border border-gray-400 rounded-md">
                    <div>
                      <DatePicker
                        selected={entry.startDate || null} // Use entry.startDate here
                        onChange={(date: Date) =>
                          handleChangeEducation(index, "startDate", date)
                        }
                        selectsStart
                        startDate={entry.startDate || null} // Use entry.startDate here
                        endDate={entry.endDate || null} // Use entry.endDate here
                        minDate={new Date(1960, 0, 1)}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        className="bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[110px]"
                      />
                    </div>

                    <div>
                      <span className="absolute right-[148px] top-1/4 ">-</span>
                    </div>
                    <div className="pl-6 ">
                      <DatePicker
                        selected={entry.endDate || null} // Use entry.endDate here
                        onChange={(date: Date) =>
                          handleChangeEducation(index, "endDate", date)
                        }
                        selectsEnd
                        startDate={entry.startDate || null} // Use entry.startDate here
                        endDate={entry.endDate || null} // Use entry.endDate here
                        minDate={entry.startDate || new Date()} // Use entry.startDate here
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        className="bg-transparent rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[140px]"
                      />
                    </div>
                  </div>
                  <ErrorMessage
                    name="startDate"
                    component="div"
                    className="error"
                  />
                  <ErrorMessage
                    name="endDate"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <button onClick={() => handleRemoveEducation(index)}>Remove</button> */}
        </div>
      ))}
      {/* <button onClick={handleAddEducation}>Add</button> */}
    </div>
  );
};

export default Education;
