
import axiosService from './axios';

const API_URL = process.env.REACT_APP_API_URL;

export default class Payslip_API {
  
  static async getPayslip(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}payslip`,
    });
  }
  static async getPayslipData(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}payslip/data`,
    });
  }
  
}
