import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Layout from "../layout/index";
import { useAppSelector } from "../redux/hook/authHook";
import { RootState } from "../redux/store";
import AccessDenied from "../utilities/components/accessDenied";

const PrivateRoute: React.FC = () => {
  const location = useLocation();

  const { user, isAuthenticated } = useAppSelector(
    (state: RootState) => state.auth
  );

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isAuthenticated) {
    return <Layout />;
  }

  // Default behavior for other cases
  return <AccessDenied />;
};

export default PrivateRoute;
