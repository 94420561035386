import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { workAniversary } from '../../../utilities/types/types';
import Auth_API from '../../../api/authService/authservice';

interface workState {
  work: workAniversary[];
}

// Create an async thunk to fetch employee data
export const getWorkAniversary = createAsyncThunk('work', async () => {
  try {
    const response = await Auth_API.employementDate();
    return response.data.employmentDate;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const workAniversarySlice = createSlice({
  name: 'workAniversary',
  initialState: {
    work: [],
  } as workState,
  reducers: {
    setWorkAniversary: (state, { payload }) => {
      state.work = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(ActiveEmployee.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setWorkAniversary } = workAniversarySlice.actions;

export default workAniversarySlice.reducer;
