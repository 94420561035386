import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FileValue } from '../../utilities/types/types';

import Document_API from '../../api/uploadService';


interface FileState {
  file: FileValue[];
}

export const getDoc = createAsyncThunk('file', async () => {
  try {
    const response = await Document_API.getDocument();
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const fileSlice = createSlice({
  name: 'file',
  initialState: {
    file: [],
  } as FileState,
  reducers: {
    setFile: (state, { payload }) => {
      state.file = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allExpense.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setFile } = fileSlice.actions;

export default fileSlice.reducer;
