import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Leave_API from '../../../api/leaveService';
import { Leave } from '../../../utilities/types/types';

interface AllLeaveState {
  allLeave: Leave[];
}

export const getAllLeave = createAsyncThunk('all-Leave', async () => {
  try {
    const response = await Leave_API.getAllLeave();
    return response.data.data;
    
  } catch (error) {
    throw error;
  }
});

// Create a slice
const allLeaveSlice = createSlice({
  name: 'all-Leave',
  initialState: {
    allLeave: [],
  } as AllLeaveState,
  reducers: {
    setAllLeaves: (state, { payload }) => {
      state.allLeave = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allLeave.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setAllLeaves } = allLeaveSlice.actions;

export default allLeaveSlice.reducer;
