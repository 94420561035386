import {
  
  ExpenseId,
  
  ExpenseValues,
  SendMessageValues,
} from '../utilities/types/types';
import axiosService from './axios';
import uploadAxiosService from './axiosUpload';

const API_URL = process.env.REACT_APP_API_URL;

export default class Expense_API {
  static async create(data: any): Promise<any> {
    return await uploadAxiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}expense/create`,
    });
  }
  static async getAllExpense(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}expense`,
    });
  }
  static async getOneExpense(expenseId: ExpenseId): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}expense/${expenseId}`,
    });
  }
  static async getApprovedExpense(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}expense/`,
      params: {
        status: 'approved',
      },
    });
  }
  static async getReimbursedExpense(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}expense/`,
      params: {
        status: 'reimbursed',
      },
    });
  }
  static async getRequestExpense(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}expense/`,
      params: {
        status: 'request',
      },
    });
  }

  // static async createExpense(): Promise<any> {
  //   return await axiosService({
  //     method: 'POST',
  //     url: `${API_URL}create`,
  //   });
  // }
  static async sendMessageExpense(
    expenseId: ExpenseId,
    data: SendMessageValues
  ): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      url: `${API_URL}expense/message/${expenseId}`,
      data: data,
    });
  }

  // static async editExpense(): Promise<any> {
  //   return await axiosService({
  //     method: 'PATCH',
  //     url: `${API_URL}update/${ExpenseId}`,
  //   });
  // }

  static async updateExpense(
    data: ExpenseValues,
    expenseId: ExpenseId
  ): Promise<any> {
    return await uploadAxiosService({
      method: 'PATCH',
      data: data,
      url: `${API_URL}expense/update/${expenseId}`,
    });
  }
  static async category(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}expense/get/category`,
    });
  }
  static async allEmployees(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}expense/get/employees`,
    });
  }
  static async getActivity(expenseId: ExpenseId): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}expense/get/activity/${expenseId}`,
    
    });
  }
}
