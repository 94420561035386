// DocumentUpload.tsx
import React, { useState, ChangeEvent } from 'react';
import { useAppDispatch } from '../../../redux/hook/authHook';
import { uploadDocument } from '../../../redux/slices/auth/fileUpload';
import FileUploadSection from './fileUpload';
import { toast } from 'react-toastify';

interface UploadProps {
  onClose: () => void;
}
const DocumentUpload = ({ onClose }: UploadProps) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [value, setValue] = useState('');
  const [error, setError] = useState<string>(' ');

  const dispatch = useAppDispatch();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  const handleUploadProgress = (progress: number) => {
    setProgress(progress);

    if (progress === 99) {
      setSelectedFile(null);
      setIsUploading(false);
      setValue('');
    }
  };

  const handleUpload = () => {
    if (selectedFile && value.trim() !== '') {
      dispatch(uploadDocument({ file: selectedFile, documentType: value }));
      setIsUploading(true);
      setError('');
    } else {
      // Set error if the input is empty
      setError('Document name is required');
    }
    if (progress === 100) {
      toast.success('Document uploaded successfully', {
        position: 'top-center',
        autoClose: 3000,
        theme: 'colored',
        style: { backgroundColor: '#00989B', color: 'white' },
      });
      setSelectedFile(null);
      window.location.reload();
    }
  };

  const handleDelete = async () => {
    if (selectedFile) {
      setSelectedFile(null);
      setIsUploading(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    if (value.trim()) {
      setError('');
    }
  };

  return (
    <div>
      <div className='relative inline-block w-full text-left'>
        <p>
          Document Type <span className='text-red-600'>*</span>
        </p>
        <div className=''>
          <input
            type='text'
            value={value}
            onChange={handleInputChange}
            className='font-medium py-2 px-4 inline-flex items-center justify-between rounded-lg border-solid border-2 border-gray-300 w-[450px]'
          />
          {error && <p className='text-red-500'>{error}</p>}
        </div>
      </div>

      <FileUploadSection
        isUploading={isUploading}
        handleUpload={handleUpload}
        handleFileChange={handleFileChange}
        selectedFile={selectedFile}
        handleDelete={handleDelete}
        setProgress={handleUploadProgress}
      />
    </div>
  );
};

export default DocumentUpload;
