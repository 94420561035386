import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Employee } from "../../../utilities/types/types";
import Expense_API from "../../../api/expenseService";

interface EmployeeState {
  allEmployee: Employee[];
  status: "idle" | "loading" | "failed"; // State to handle loading and error
}

export const getAllEmployee = createAsyncThunk("All Employee", async () => {
  try {
    const response = await Expense_API.allEmployees();
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

const AllEmployeeSlice = createSlice({
  name: "All Employee",
  initialState: {
    allEmployee: [],
    status: "idle", // Initial status is idle
  } as EmployeeState,
  reducers: {
    setAllEmployee: (state, action) => {
      state.allEmployee = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployee.pending, (state) => {
        state.status = "loading"; // Set status to 'loading' on pending
      })
      .addCase(getAllEmployee.fulfilled, (state, action) => {
        state.status = "idle"; // Set status to 'idle' on successful fetch
        state.allEmployee = action.payload;
      })
      .addCase(getAllEmployee.rejected, (state) => {
        state.status = "failed"; // Set status to 'failed' on fetch failure
      });
  },
});

export const { setAllEmployee } = AllEmployeeSlice.actions;

export default AllEmployeeSlice.reducer;
